<template>
  <div>
    <div class="sift">
      <div class="title">报表查询</div>
      <div class="inquire">
        <el-form :model="formInline" class="demo-form-inline" label-width="80px" size="mini" inline @submit.native.prevent>
          <el-form-item>
            <strong slot="label">日期</strong>
            <el-date-picker
              v-model="date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              size="mini"
              style="width:240px"
              @change="handleChange"
              :picker-options="pickerOptions"
              >
            </el-date-picker>
          </el-form-item>
          <el-form-item v-for="(item, index) in cfOrgRept.cfFlowReptcontList" :key="item.cfFlowReptcontId" v-if="item.isSearch == '0'"> 
            <strong slot="label">{{item.label}}</strong>
            <el-input v-model="formInline.query[index].searchValue" placeholder="请输入产品名称" clearable  @keyup.enter.native="inquire" @clear="inquire" style="width:240px" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="inquire" style="background:#0C75E5">查询</el-button>
            <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- <el-tabs type="border-card" v-model="activeId" @tab-click="handleClick">
      <div v-if="!orgReptList.length" style="color:#999">暂无数据~</div>
      <el-tab-pane v-for="(item, index) in orgReptList" :key="index" :label="item.showName" :name="item.cfFlowReptId + ''"> -->
        
        <el-table
          border
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
          :cell-style="{'vertical-align':'top'}"
          :height="tableHeight"
          v-if="$route.query.cfFlowReptId == cfOrgRept.cfFlowReptId"
          v-loading="loading"
          :row-class-name="rowClass"
          default-expand-all
          row-key="id"
          :tree-props="{children: 'secondList', hasChildren: 'hasSecondList'}"
        >
          <el-table-column prop="name" label="名称" min-width="100" v-if="cfOrgRept.flowRepttype != '6' && cfOrgRept.flowRepttype != '7'"></el-table-column>
          <el-table-column v-for="(val, id) in cfOrgRept.cfFlowReptcontList" :key="id" :prop="val.prop" :label="val.label" :align="(cfOrgRept.flowRepttype == '6' || cfOrgRept.flowRepttype == '7' )? 'center' : 'right'" header-align="center" :min-width="cfOrgRept.flowRepttype == '4' ? '275' : '160'">
            <template slot-scope="scope">
              <div v-if="cfOrgRept.flowRepttype == '6' || cfOrgRept.flowRepttype == '7'">
                <template v-if="val.worktype == 'b'">{{scope.row.b[val.prop]}}</template>
                <template v-else-if="val.worktype == 'n'">{{scope.row.n[val.prop]}}</template>
                <template v-else-if="val.worktype == 'p'">{{scope.row.p[val.prop]}}</template>
                <template v-else>{{scope.row.b[val.prop]}}</template>
              </div>
              <div v-for="(el, cfFlowReptcontId) in scope.row.list" :key="cfFlowReptcontId" v-if="cfOrgRept.flowRepttype != '6' && cfOrgRept.flowRepttype != '7' && el.cfFlowReptcontId == val.cfFlowReptcontId">
                <template v-if="cfOrgRept.flowRepttype != '4'">
                  <div v-if="val.labelType == '4'">{{el.reptValue | comdify}}</div>
                  <div v-else-if="val.labelType == '5'">{{el.reptValue | filter_handleTimeRange}}</div>
                  <div v-else-if="el.subdealtype == '70'" style="display:flex;justify-content: flex-end">
                    <div style="margin-right: 10px">
                      出勤
                      <span style="color:blue;cursor: pointer" @click="getLogs(el)">{{el.reptValue}}</span>
                    </div>
                    <div>
                      异常
                      <span style="color:red;cursor: pointer" @click="getLogs(el)">{{el.reptValue2}}</span>
                    </div>
                  </div>
                  <div v-else>{{el.reptValue}}</div>
                </template>
                <el-table
                  v-if="cfOrgRept.flowRepttype == '4'"
                  :data="el.workList"
                  tooltip-effect="dark"
                  style="width: 100%"
                  :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
                >
                  <el-table-column prop="name" label="名称" header-align="center" min-width="100">
                    <template slot-scope="scope1">
                      <a class="link" @click="handleDetail(scope1.row)" style="user-select: none;">{{scope1.row.name}}</a>
                    </template>
                  </el-table-column>
                  <el-table-column prop="ptotal" label="金额" align="right" min-width="150" header-align="center">
                    <template slot-scope="scope1">
                      {{scope1.row.ptotal | comdify}}
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </template>
          </el-table-column>
        </el-table>
      <!-- </el-tab-pane>
    </el-tabs> -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      append-to-body
      top="59px"
      width="50%">
      <div style="height:100%;overflow: auto">
        <el-table
          border
          :data="attendLogs"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
          v-loading="loading1"
        >
          <el-table-column prop="attendDate" label="考勤日期"></el-table-column>
          <el-table-column prop="attendShiftInfo" label="班次信息"></el-table-column>
          <el-table-column prop="attendResult" label="考勤结果">
            <template slot-scope="scope">
              {{ scope.row.attendResult == '0' ? '休假' : (scope.row.attendResult == '1' ? '正常' : '异常') }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      
      <!-- <div class="dialog-content">
        <div class="dialog-row">
          <div class="row-left">考勤日期：</div>
          <div class="row-right">2024-03-01</div>
        </div>
        <div class="dialog-row">
          <div class="row-left">班次信息：</div>
          <div class="row-right">09:00-17:30</div>
        </div>
        <div class="dialog-row">
          <div class="row-left">考勤结果：</div>
          <div class="row-right">正常</div>
        </div>
        <div class="dialog-row">
          <div class="row-left">考勤日志：</div>
          <div class="row-right">
            <div class="log-list" v-for="(item, index) in attendLogs" :key="index">
							<div class="list-left">
								<div class="list-dot"></div>
								<div class="list-line"></div>
							</div>
							<div class="list-right">
								<div class="list-text">
									<div class="list-row">
										<div class="list-row-text">{{ item.attendType == '1' ? '上班' : '下班'}}</div>
										<div class="list-row-data">
											{{ item.checkInTime ? parseTime(item.checkInTime, '{h}:{i}') : '' }}
											<div class="list-status" v-if="item.attendStatus != '0' ">{{ item.attendStatus == '2' ? '旷工' : (item.attendStatus == '1' ? (item.attendType == '1' ? '迟到' : '早退') : '')}}</div>
										</div>
									</div>
									<div class="clock-text">
										{{ item.attendPozStatus == '0' ? '考勤范围内' : (item.attendPozStatus == '1' ? '考勤范围外' : '')}}
									</div>
								</div>
								
							</div>
						</div>
          </div>
        </div>
      </div> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import url from '@/config/url'
import qs from 'qs'
  export default {
    data() {
      return {
        tableData: [],
        orgReptList: [],
        activeId: '',
        date: [],
        dialogVisible: false,
        productsList: [],
        pageNum1: 1,
        pageSize1: 10,
        total1: 0,
        loading: true,
        loading1: true,
        ofoWorkId: '',
        tableHeight: window.innerHeight - 357,
        ofoFlowWork: {},
        proTotal: {
          ctotal: 0,
          ctotalNovat: 0,
          ctotalVat: 0,
          ptotal: 0,
          ptotalNovat: 0,
          ptotalVat: 0,
        },
        subTotal: {
          ctotal: 0,
          ctotalNovat: 0,
          ctotalVat: 0,
          ptotal: 0,
          ptotalNovat: 0,
          ptotalVat: 0,
        },
        navList: [],
        isActive: 0,
        thingsList: [],
        pageNum2: 1,
        pageSize2: 10,
        total2: 0,
        title: '考勤明细',
        attendLogs: [{},{}],
        cfOrgRept: {},
        formInline: {
          query: []
        }
      }
    },
    created () {
      // console.log(111);
      if(this.$route.path == '/report/org') {
        this.getList()
      }
      
      this.$nextTick(() => {
        this.tableHeight = window.innerHeight - 160;
      })
      window.onresize = () => {   //屏幕尺寸变化就重新赋值
        return (() => {
          this.tableHeight = window.innerHeight - 160;
        })()
      }
    },
    updated(){
      this.$nextTick(() => {
        // console.log(this.$refs.table);
        // if(this.$refs) {
        //   this.$refs.table[0].doLayout() 
        // }
            
          // table是在表格中ref=‘table’
          // doLayout	对 Table 进行重新布局。当 Table 或其祖先元素由隐藏切换为显示时，可能需要调用此方法
      })
    },
    methods: {
      // 获取考勤明细
      getLogs(el) {
        // console.log(el);
        this.dialogVisible = true
        this.loading1 = true
        this.$http.get(url.getAttendUserLogs, {params: {
          bussId: el.id
        }}).then(res => {
          this.attendLogs = res.data.data
          this.loading1 = false
        })
      },
      rowClass({row, column, rowIndex, columnIndex}){
        if(row.name == '合计') {
          return 'fixed-row'
        }
      },
      getList() {
        this.$http.get(url.getCfOrgReptList).then(res => {
          if(res.data.code == 200) {
            this.orgReptList = res.data.data
            if(this.orgReptList.length) {
              this.cfOrgRept = this.orgReptList.find(item => item.cfFlowReptId == this.$route.query.cfFlowReptId)
              this.formInline.query = this.cfOrgRept.cfFlowReptcontList.map(item => {
                return {
                  ...item,
                  searchValue: ''
                }
              })
              this.activeId = this.cfOrgRept.cfFlowReptId
              // console.log(this.$route.query);
              this.getOrgRept()
            }
          }
        })
      },
      handleDetail(item) {
        // this.productsList = this.thingsList = []
        // this.title = item.name
        // this.dialogVisible = true
        // this.ofoWorkId = item.ofoWorkId
        // this.$http.get(url.getFlowDealAndWork, {params: {ofoWorkId: this.ofoWorkId}}).then(res => {
        //   if(res.data.code == 200) {
        //     // this.ofoFlowDeal = res.data.data.ofoFlowDeal
        //     this.ofoFlowWork = res.data.data.ofoFlowWork
            
        //     this.getWorkResList({pageNum: 1, pageSize: 10})
        //     this.getWorkThingList({pageNum: 1, pageSize: 10})
        //     this.navList = [
        //       {
        //         icon: 'iconfont icon-lvzhou_mingxi',
        //         text: this.ofoFlowWork.thingtypeName + '（明细）',
        //         isShow: this.ofoFlowWork.thingtype && this.ofoFlowWork.workkindmanagetype != '0',
        //       },
        //       {
        //         icon: 'iconfont icon-chanpin',
        //         text: this.ofoFlowWork.kindtypeName,
        //         isShow: this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype != '0'
        //       },
        //     ]
        //     let navArr = []
        //     this.navList.forEach((item, index) => {
        //       if(item.isShow) {
        //         navArr.push(index)
        //       }
        //     })
        //     this.isActive = navArr[0]
        //     for(let key in this.proTotal) {
        //       this.proTotal[key] = res.data.data.work[key]
        //     }
        //   }
        // })
        
      },

      getOrgRept() {
        this.tableData = []
        this.loading = true
        let query = this.formInline.query.filter(item => item.isSearch == '0').map(val => {
          return {
            worktype: val.worktype,
            prop: val.prop,
            searchValue: val.searchValue,
          }
        })
        this.$http.post(url.getOrgRept, 
          {
            cfFlowReptId: this.activeId,
            // ofoItemId: this.ofoItemId,
            beginTime: this.date ? this.date[0] : '',
            endTime: this.date ? this.date[1] : '',
            queryList: query
          }).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.tableData = res.data.data
            if(this.cfOrgRept.flowRepttype !== '6' && this.cfOrgRept.flowRepttype != '7') {
              this.tableData.push({
                name: '合计',
                list: JSON.parse(JSON.stringify(this.orgReptList[this.isActive].cfFlowReptcontList)),
                secondList: []
              })
              this.tableData[this.tableData.length - 1].list.forEach(item => {
                item.reptValue = 0
                item.reptValue2 = 0
                this.tableData.forEach((val, index) => {
                  val.list.forEach(el => {
                    if(el.cfFlowReptcontId == item.cfFlowReptcontId && index != this.tableData.length - 1) {
                      // console.log(el.reptValue);
                      item.reptValue += Number(el.reptValue)
                    }
                  })
                  val.secondList.forEach(val1 => {
                    val1.list.forEach(el => {
                      if(el.cfFlowReptcontId == item.cfFlowReptcontId) {
                        // console.log(el.reptValue);
                        item.reptValue += Number(el.reptValue)
                        if(el.subdealtype == '70') {
                          item.reptValue2 += Number(el.reptValue2)
                        }
                      }
                    })
                  })
                })
              });
            }
            
            // this.orgReptList[this.isActive].cfFlowReptcontList.forEach(item => {
            //   item.total = 0
            //   this.tableData.forEach(val => {
            //     val.list.forEach(el => {
            //       if(el.cfFlowReptcontId == item.cfFlowReptcontId) {
            //         // console.log(el.reptValue);
            //         item.total += Number(el.reptValue)
            //       }
            //     })
            //   })
            // });
          }
        })
      },
      handleChange() {
        this.getOrgRept()
      },
      handleClick(tab) {
        // console.log(this.activeId);
        this.isActive = tab.index
        this.getOrgRept()
      },
      // 查询按钮
      inquire() {
        // this.pageNum = 1
        this.getOrgRept()
      },
      // 重置按钮
      reset() {
        // for(let key in this.formInline) {
        //   this.formInline[key] = ""
        // }
        this.formInline.query.forEach(item => {
          item.searchValue = ''
        })
        this.date = []
        this.getOrgRept()
        // this.formInline.cbowhId = null
        // this.pageNum = 1
        // this.getList()
      },
    },
    watch:{
      $route:{
        handler(val, oldval){
          // console.log(val);//新路由信息
          // console.log(oldval);//老路由信息
          if(val.path == '/report/org' && this.orgReptList.length) {
            this.cfOrgRept = this.orgReptList.find(item => item.cfFlowReptId == val.query.cfFlowReptId)
            this.formInline.query = this.cfOrgRept.cfFlowReptcontList.map(item => {
              return {
                ...item,
                searchValue: ''
              }
            })
            this.activeId = this.cfOrgRept.cfFlowReptId
            // console.log(this.$route.query);
            this.getOrgRept()
          }
        },
        // 深度观察监听
        deep: true
      }
    }
  }
</script>

<style lang="scss" scoped>
.sift {
  width: 100%;
  background-color: #fff;
  box-shadow: 2px 3px 8px 0px rgba(208, 208, 208, 0.5);
  border-radius: 11px;
  padding: 16px;
  box-sizing: border-box;
  margin-bottom: 16px;
}
.title {
  height: 14px;
  font-size: 14px;
  font-family: STHeitiSC-Medium, STHeitiSC;
  font-weight: 500;
  color: #282828;
  line-height: 15px;
  padding-left: 8px;
  border-left: #0c75e5 2px solid;
  margin-bottom: 16px;
  font-weight: bolder;
}
::v-deep .el-dialog {
  max-height: calc(100vh - 60px);
  overflow: hidden;
  .el-dialog__body {
    max-height: calc(100vh - 300px);
    overflow: auto;
  }
}
::v-deep .el-table{
  .el-table__body {
    // 吸底
    .fixed-row{
      /* display: inline-block; */
      position: sticky;
      bottom: 0;
      width: 100%;
      background: #f5f7fa;
    }
  }
}
.dialog-content {
  font-size: 16px;
  color: #000;
  .dialog-row {
    display: flex;
    margin-bottom: 10px;
    .row-left {
      color: #999;
    }
    .row-right {
      flex: 1;
      .log-list {
        display: flex;
        margin-bottom: 12px;
        
        .list-left {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 10px;
          margin-top: 10px;
      
          .list-dot {
            width: 16px;
            height: 16px;
            background-color: #1479E5;
            border-radius: 50%;
            margin-top: 8px;
            margin-bottom: 16px;
          }
      
          .list-line {
            width: 2px;
            flex: 1;
            background-color: #1479E5;
          }
        }
      
        .list-right {
          flex: 1;
          display: flex;
          justify-content: space-between;
          // align-items: center;
          border-bottom: 1px solid #cccccc;
      
          .list-text {
            flex: 1;
            // color: #999898;
            line-height: 40px;
            .list-row {
              display: flex;
              /* font-size: 20px; */
              .list-row-text {
                width: 45px;
              }
              .list-row-data {
                flex: 1;
                display: flex;
                align-items: center;
                .list-status {
                  color: #ffd361;
                  font-size: 14px;
                  padding: 2px 5px;
                  border: 1px solid #ffd361;
                  border-radius: 5px;
                  height: 14px;
                  line-height: 1;
                  margin-left: 5px;
                }
              }
            }
          }
      
          .list-appstatus {
            color: #1479E5;
          }
        }
      
      
      }
    }
  }
}
</style>