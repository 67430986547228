<template>
  <div class="detail">
    <div class="main">
      <!-- <el-button class="extend">扩展信息</el-button> -->
      <el-button class="refresh" icon="el-icon-refresh" @click="handleRefresh" v-if="handleDutys(allData.dutys, 'selType') && isShare == '1'">配置更新</el-button>
      <div style="float:right;display:flex;align-items:center" v-if="isShare == '1'">
        <el-tooltip class="item" effect="dark" content="扫码文档" placement="top-end">
          <!-- <el-button class="edit" icon="el-icon-full-screen" type="text" style="font-size:16px;padding: 0" v-print="print1"></el-button> -->
          <i class="iconfont icon-saoma1" style="font-size:20px;margin-right:16px;color:#409eff" v-print="print1"></i>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="扫码报工" placement="top-end">
          <!-- <el-button class="edit" icon="el-icon-full-screen" type="text" style="font-size:16px;padding: 0" v-print="print"></el-button> -->
          <i class="iconfont icon-saoma2" style="font-size:20px;margin-right:16px;color:#409eff" v-print="print"></i>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" :content="allData.isCollect == '0' ? '取消收藏' : '收藏'" placement="top-end">
          <el-button class="edit" :icon="allData.isCollect == '0' ? 'el-icon-star-on' : 'el-icon-star-off'" @click="handleCollection(allData, 'c')" type="text" :style="{'font-size':'20px',padding: 0}"></el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="分享" placement="top-end">
          <el-button class="edit" icon="el-icon-share" @click="handleCollection(allData, 's')" type="text" style="font-size:16px;padding: 0" v-if="handleDutys(allData.dutys, 'shareType')"></el-button>
        </el-tooltip>
      </div>
      <!-- <div style="float:right;" v-else>
        <el-button size="mini" icon="el-icon-check" type="primary" @click="handleEditShareStatus">已阅</el-button>
      </div> -->
      <el-popover
        placement="bottom"
        width="400"
        trigger="click">
        <div style="max-height:calc(100vh - 190px);overflow-y:auto">
          <el-descriptions :column="1">
            <el-descriptions-item label="名称">{{allData.name}}</el-descriptions-item>
            <el-descriptions-item label="代码">{{allData.code}}</el-descriptions-item>
            <el-descriptions-item label="人员">{{allData.userName}}</el-descriptions-item>
            <el-descriptions-item label="类型">{{allData.cfflowitemName}}</el-descriptions-item>
            <el-descriptions-item label="日期">{{allData.itemDate | filter_handleDate}}</el-descriptions-item>
            <el-descriptions-item label="发布">
              <DictTag :options="dropDown.documentstatus" :value="allData.documentstatus"/>
            </el-descriptions-item>
            <el-descriptions-item label="菜单">
              <DictTag :options="dropDown.itemType" :value="allData.itemType"/>
            </el-descriptions-item>
            <el-descriptions-item label="描述">{{allData.remarks}}</el-descriptions-item>
          </el-descriptions>
          <el-button class="extend" type="text" @click="isShowExtend = !isShowExtend">扩展信息
            <i class="el-icon-arrow-down el-icon--right" v-if="!isShowExtend"></i>
            <i class="el-icon-arrow-up el-icon--right" v-if="isShowExtend"></i>
          </el-button>
          <el-descriptions :column="1" v-if="isShowExtend" :labelStyle="{'max-width': '100px'}">
            <el-descriptions-item v-for="(item, index) in allData.dimeList" :key="index" :label="item.dimeName + '*'">
              <DimeTag :dimeObj="item"/>
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="header link" slot="reference">{{allData.name}}</div>
      </el-popover>
      
      <el-tabs v-model="configIndex">
        <el-tab-pane label="流程" name="1">
          <!-- <div class="deal">
            <div class="deal-item" v-for="(item, index) in allData.ofoFlowDealList" :key="index" :class="[ofoDealId == item.ofoDealId ? 'deal-isActive' : '']" @click="changeDeal(index)">{{item.name}}</div>
          </div>
          <div class="work">
            <div class="work-left">
              <div class="work-item" v-for="(val, id) in ofoDeal.ofoFlowWorkList" :key="id" :class="[ofoFlowWorkId == val.ofoFlowWorkId ? 'work-isActive' : '']" @click="changeWork(id)">
                <el-badge :value="val.draftCount" class="item" style="position:absolute;top:-8px;right:-10px" v-if="val.draftCount"></el-badge>
                {{val.name}}</div>
            </div>
            <div class="work-right">
              <Activity 
                :ofoFlowDeal="ofoDeal"
                :ofoFlowWork="ofoFlowWork"
                :ofoItemName="allData.name"
                :siftForm="siftForm"
                ref="activity"
                :isShare="isShare"
                :restaurants="restaurants"
                :resourceList1="resourceList"
                @changeCopy="changeCopy"/>
            </div>
          </div> -->
          <el-tabs v-model="ofoDealId" type="card" @tab-click="changeDeal" class="tabs1">
            <el-tab-pane v-for="(item, index) in allData.ofoFlowDealList" :key="index" :label="item.name" :name="item.ofoDealId + ''">
              <el-tabs :tab-position="tabPosition" class="tabs2" v-model="ofoFlowWorkId" type="card" @tab-click="changeWork">
                <el-tab-pane v-for="(val, id) in item.ofoFlowWorkList" :key="id" :label="val.name" :name="val.ofoFlowWorkId + ''">
                  <div slot="label">
                    <div :class="[ofoFlowWorkId == val.ofoFlowWorkId ? 'work-isActive' : '', 'work-item']">
                      {{val.name}}
                      <el-badge :value="val.draftCount" class="item" style="position:absolute;top:-8px;right:-10px" v-if="val.draftCount"></el-badge>
                    </div>
                  </div>
                  
                  <Activity 
                    :ofoFlowDeal="ofoDeal"
                    v-if="ofoFlowWorkId == val.ofoFlowWorkId + ''"
                    :ofoFlowWork="ofoFlowWork"
                    :ofoItemName="allData.name"
                    :siftForm="siftForm"
                    ref="activity"
                    :isShare="isShare"
                    :restaurants="restaurants"
                    :resourceList1="resourceList"
                    @changeCopy="changeCopy"
                    :params="allData.params"/>
                </el-tab-pane>
              </el-tabs>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <!-- 附件列表 -->
        <el-tab-pane label="文件" name="2">
          <File :ofoFlowDealList="allData.ofoFlowDealList" :name="allData.name" :ofoItemId="ofoItemId" />
        </el-tab-pane>
        <!-- 报表 -->
        <el-tab-pane label="报表" name="3">
          <Report :cfFlowItemId="allData.cfflowitemId" :ofoItemId="ofoItemId" />
        </el-tab-pane>
        <!-- <el-tab-pane label="报表">报表</el-tab-pane> -->
        <el-tab-pane label="配置" v-if="isShowConfig" name="4">
          <el-descriptions :column=3>
            <el-descriptions-item label="代码">{{allData.code}}</el-descriptions-item>
            <el-descriptions-item label="名称">{{allData.name}}</el-descriptions-item>
            <el-descriptions-item label="流程组件"></el-descriptions-item>
            <el-descriptions-item label="流程模板">{{allData.cfflowitemName}}</el-descriptions-item>
            <el-descriptions-item label="公司"></el-descriptions-item>
            <el-descriptions-item label="菜单">
              <DictTag :options="dropDown.itemType" :value="allData.itemType"/>
            </el-descriptions-item>
            <el-descriptions-item label="描述">{{allData.remarks}}</el-descriptions-item>
          </el-descriptions>
          <el-tabs v-model="activeNameConfig" type="card" @tab-click="handleClickConfig" class="tabs1">
            <el-tab-pane v-for="(item, index) in allData.ofoFlowDealList" :key="index" :label="item.name" :name="item.ofoDealId + ''">
              <!-- <div class="title">交易描述</div> -->
              <el-descriptions>
                <el-descriptions-item label="甲方资源类型">{{item.restype1Name}}</el-descriptions-item>
                <el-descriptions-item label="乙方资源类型">{{item.restype2Name}}</el-descriptions-item>
                <el-descriptions-item label="类型">
                  <DictTag :options="dropDown.kindDealType" :value="item.dealtype"/>
                </el-descriptions-item>
                <el-descriptions-item label="甲方资源">{{item.resource1Name}}</el-descriptions-item>
                <el-descriptions-item label="乙方资源">{{item.resource2Name}}</el-descriptions-item>
                <el-descriptions-item label="排序">{{item.order}}</el-descriptions-item>
              </el-descriptions>
              <div class="title">活动列表</div>
              <el-table v-if="activeNameConfig === item.ofoDealId + ''"
                :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
                :data="tableData"
                style="width: 100%"
                :max-height="tableHeight">
                <el-table-column label="活动组件" fixed align="center" prop="name" width="150"/>
                <!-- <el-table-column label="流程" align="center"> -->
                  <el-table-column label="排序" align="center" prop="order" />
                  <!-- <el-table-column label="发布类型" align="center" prop="reletype">
                    <template slot-scope="scope">
                      <DictTag :options="dropDown.publishType" :value="scope.row.reletype"/>
                    </template>
                  </el-table-column> -->
                <!-- </el-table-column> -->

                <el-table-column label="科目" align="center">
                  <el-table-column label="账套" align="center" prop="accttype">
                    <template slot-scope="scope">
                      <DictTag :options="dropDown.subjectAccountType" :value="scope.row.accttype"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="凭证" align="center" prop="ojdttype">
                    <template slot-scope="scope">
                      <DictTag :options="dropDown.ojdtType" :value="scope.row.ojdttype"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="库存" align="center" prop="stockaccttype">
                    <template slot-scope="scope">
                      <DictTag :options="dropDown.stockacctType" :value="scope.row.stockaccttype"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="报表" align="center" prop="subdealtype">
                    <template slot-scope="scope">
                      <DictTag :options="dropDown.subdealType" :value="scope.row.subdealtype"/>
                    </template>
                  </el-table-column>
                </el-table-column>

                <el-table-column label="导航" align="center">
                  <el-table-column label="前置" align="center" prop="beforeworkName" />
                  <el-table-column label="复制" align="center" prop="workcopytype">
                    <template slot-scope="scope">
                      <DictTag :options="dropDown.workcopyType" :value="scope.row.workcopytype"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="后置" align="center" prop="afterworkName" />
                </el-table-column>

                <el-table-column label="前台" align="center">
                  <el-table-column label="核算方式" align="center" prop="costmanagetype">
                    <template slot-scope="scope">
                      <DictTag :options="dropDown.costmanangeType" :value="scope.row.costmanagetype"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="核算对象" align="center" prop="workkindmanagetype">
                    <template slot-scope="scope">
                      <DictTag :options="dropDown.flowWorkkindmanageType" :value="scope.row.workkindmanagetype"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="资源绑定" align="center" prop="kindtypeName" />
                  <el-table-column label="内容绑定" align="center" prop="thingtypeName" />
                  <el-table-column label="文档绑定" align="center" prop="kinddocutypeName" />
                  <el-table-column label="交易方内容显示" align="center" prop="thingDimeType">
                    <template slot-scope="scope">
                      <DictTag :options="dropDown.thingDimeType" :value="scope.row.thingDimeType"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="仓库选择" align="center" prop="chooseOwh">
                    <template slot-scope="scope">
                      <DictTag :options="dropDown.yesNo" :value="scope.row.chooseOwh"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="结算方式" align="center" prop="settleType">
                    <template slot-scope="scope">
                      <DictTag :options="dropDown.settleType" :value="scope.row.settleType"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="移动端" align="center" prop="appEdit">
                    <template slot-scope="scope">
                      <DictTag :options="dropDown.appEdit" :value="scope.row.appEdit"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="正式后编辑" align="center" prop="appEdit">
                    <template slot-scope="scope">
                      <DictTag :options="dropDown.appEdit" :value="scope.row.statusEdit"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="是否协同" align="center" prop="isNotice">
                    <template slot-scope="scope">
                      <DictTag :options="dropDown.yesNo" :value="scope.row.isNotice"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="核销类型" align="center" prop="checkType">
                    <template slot-scope="scope">
                      <DictTag :options="dropDown.checkType" :value="scope.row.checkType"/>
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <!-- 导航 -->
        <el-tab-pane label="导航" v-if="isShowConfig" name="5">
          <Nav :allData="allData" @clickOfoFlowWork="clickOfoFlowWork" />
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog title="分享项目" :visible.sync="dialogShareVisible"
      :append-to-body="true" width="30%" top="59px">
      <el-form :model="form" label-width="60px">
        <el-form-item label="分享人" >
          <el-row>
            <el-col :span="16">
              <el-select v-model="form.toUserIds" placeholder="请选择分享人" style="width:97%" filterable multiple @change="changeSelect" collapse-tags>
                <el-option-group
                  v-for="group in userList"
                  :key="group.ofrResourceId">
                  <div style="padding: 0 20px">
                    <el-checkbox v-model="group.checked" @change="selectGroupAll($event,group.ofrResourceId)">{{group.resourceName}}</el-checkbox>
                  </div>
                  <el-option
                    v-for="item in group.ofrThingList"
                    :key="item.ofrThingId"
                    :label="item.name"
                    :value="item.ofrThingId + ''">
                  </el-option>
                </el-option-group>
              </el-select>
            </el-col>
            <el-col :span="8">
              <el-button @click="clickGroupBtn">组管理</el-button>
            </el-col>
          </el-row>
          
        </el-form-item>
      </el-form>
      <el-table
        :data="shareList"
        style="width: 100%"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
        v-loading="loading">
        <el-table-column
          prop="toUserName"
          label="已分享">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="分享时间">
          <template slot-scope="scope">
            {{parseTime(scope.row.createTime)}}
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="status"
          label="状态"
          width="70">
          <template slot-scope="scope">
            {{scope.row.status == '1' ? '已读' : '未读'}}
          </template>
        </el-table-column> -->
        <el-table-column
          label="操作"
          width="70">
          <template slot-scope="scope">
            <el-button size="mini" type="text" icon="el-icon-circle-close" @click.native.stop="handleCancel(scope.row, scope.$index)">取消</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogShareVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitShare">分 享</el-button>
      </div>
    </el-dialog>
    <el-dialog title="组管理" :visible.sync="dialogTableVisible">
      <div style="margin-bottom:16px">
        <el-button type="primary" plain size="mini" @click="handleAddGroup">新增</el-button>
        <!-- <el-button type="success" plain size="mini" :disabled="ids.length != 1" @click="handleEditGroup">修改</el-button>
        <el-button type="danger" plain size="mini" :disabled="ids.length != 1">删除</el-button> -->
      </div>
      
      <el-table ref="groupTable" :data="groupList" :header-cell-style="{background:'#F9F9FB',color:'#606266'}" @selection-change="handleSelect" @row-click="handleRowClick">
        <el-table-column type="selection" width="50" align="center" />
        <el-table-column property="name" label="名称"></el-table-column>
        <el-table-column property="bussIdsName" label="人员"></el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="100">
          <template slot-scope="scope">
            <el-button @click.native.stop="handleEditGroup(scope.row)" type="text" size="small">修改</el-button>
            <el-button @click.native.stop="handleDelGroup(scope.row)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">关 闭</el-button>
        <el-button type="primary" @click="confirmGroup" :disabled="ids.length != 1">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="组管理" :visible.sync="dialogGroupVisible" width="500px">
      <el-form :model="groupForm" :rules="groupRules" ref="groupForm" label-width="60px" class="demo-ruleForm">
        <el-form-item label="名称" prop="name">
          <el-input v-model="groupForm.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="人员" prop="bussIds">
          <el-select v-model="groupForm.bussIds" placeholder="请选择人员" style="width:100%" filterable multiple>
            <el-option-group
              v-for="group in userList"
              :key="group.ofrResourceId"
              :label="group.resourceName">
              <el-option
                v-for="item in group.ofrThingList"
                :key="item.ofrThingId"
                :label="item.name"
                :value="item.ofrThingId + ''">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogGroupVisible = false">关 闭</el-button>
        <el-button type="primary" @click="submitGroup('groupForm')" :loading="isLoading">提 交</el-button>
      </div>
    </el-dialog>
    <div class="modal" v-show="isShowModal" @click="isShowModal = false">
      <div id="printqr" style="display:flex;flex-direction:column;align-items: center">
        <div id="item"></div>
        <h1 style="margin-top:20px;font-size:30px">项目：{{allData.name}}</h1>
      </div>
    </div>
    <div class="modal" v-show="isShowModal" @click="isShowModal = false">
      <div id="printqr1" style="display:flex;flex-direction:column;align-items: center">
        <div id="item1"></div>
        <h1 style="margin-top:20px;font-size:30px">项目：{{allData.name}}</h1>
      </div>
    </div>
  </div>
</template>

<script>
import url from '@/config/url'
import { mapState } from 'vuex'
import DictTag from '@/components/DictTag.vue'
import Process from '@/components/project/process.vue'
import Activity from '@/components/project/activity.vue'
import DimeTag from '@/components/DimeTag.vue'
import File from '@/components/project/detail/File.vue'
import Report from '@/components/project/detail/Report.vue'
import Nav from '@/components/project/detail/Nav.vue'
import qs from 'qs'
import toWork from '@/store/toWork'
import textRange from '@/utils/textRange.js'
import QRCode from 'qrcodejs2'
  export default {
    props: {
      ofoItemId: {
        type: [Number, String]
      },
      isShare: {
        type: String,
        default: '1'
      }
    },
    components: {
      DictTag,
      Process,
      Activity,
      DimeTag,
      File,
      Report,
      Nav
    },
    computed: {
      ...mapState({
        dropDown: (state) => state.dropDown.dropDown,
        toWork: (state) => state.toWork.jumpData
      }),
    },
    data() {
      return {
        ofoDealId: '',
        // ofoItemId: ,
        allData: {},
        ofoFlowWorkId: '',
        tabPosition: 'left',
        tabIndex: 0,
        tableData:[],
        activeNameConfig: '',
        isShowExtend: false,
        change: true,
        data: {},
        isShowConfig: JSON.parse(window.sessionStorage.getItem('loginUser')).isAdmin == '0' ? true : false,
        siftForm: {
          baseId: '',
          codename: '',
          dealId: '',
          dealowhId: ''
        },
        isShowFormInline: true,
        resourceList: [],
        departmentList: [],
        dialogShareVisible: false,
        form: {
          toUserIds: [],
          obussId: ''
        },
        userList: [],
        shareList: [],
        loading: true,
        tableHeight: 0,
        // isShare: this.$route.query.isShare ? this.$route.query.isShare : '1',
        restaurants: [],
        ofoDeal: {},
        ofoFlowWork: {},
        isShowText: false,
        configIndex: '1',
        checked: false,
        dialogTableVisible: false,
        groupList: [],
        dialogGroupVisible: false,
        groupForm: {
          bussIds: [],
          name: ''
        },
        groupRules: {
          name: [
            { required: true, message: '请输入名称', trigger: 'change' }
          ],
          bussIds: [
            { required: true, message: '请选择人员', trigger: 'change' }
          ],
        },
        isLoading: false,
        ids: [],
        isShowModal: false,
        print: {
          id: 'printqr',
          popTitle: '',
        },
        print1: {
          id: 'printqr1',
          popTitle: '',
        },
      }
    },
    created () {
      if(this.toWork.ofoWorkId || this.toWork.ofoItemId) {
        this.data = this.toWork
      }
      this.$nextTick(() => {
        this.tableHeight = window.innerHeight - 122;
      })
      window.onresize = () => {   //屏幕尺寸变化就重新赋值
        return (() => {
          this.tableHeight = window.innerHeight - 122;
        })()
      }
      this.ofoItemId = this.ofoItemId ? this.ofoItemId : this.$route.params.ofoItemId
      this.isShare = this.$route.query.isShare ? this.$route.query.isShare : '1'
      this.loadData()
    },
    methods: {
      // handleQrcode() {
        
      //   // this.qrcode('item', 'https://cs.mywork.world/smbg/' + this.$route.params.ofoItemId);
      //   this.isShowModal = true
      // },
      // 生成二维码
      qrcode() {
        document.getElementById("item").innerHTML = "";
        let gid = 'item';
        let parent = document.getElementById(gid)
        let children = parent.getElementsByTagName('img');
        if(children.length > 0){
          for(let i = 0;i < children.length; i++){
            parent.removeChild(children[i]);
          }
        }
        let qrcode2 = new QRCode('item', {
          width: 400,
          height: 400, // 高度
          text: 'https://cs.mywork.world/smbg/' + this.$route.params.ofoItemId // 二维码内容
        })


        document.getElementById("item1").innerHTML = "";
        let gid1 = 'item1';
        let parent1 = document.getElementById(gid1)
        let children1 = parent1.getElementsByTagName('img');
        if(children1.length > 0){
          for(let i = 0;i < children1.length; i++){
            parent1.removeChild(children1[i]);
          }
        }
        let qrcode21 = new QRCode('item1', {
          width: 400,
          height: 400, // 高度
          text: 'https://cs.mywork.world/smwd/' + this.$route.params.ofoItemId // 二维码内容
        })
      },
      // 已阅
      handleEditShareStatus() {
        this.$http.post(url.editShareStatus, qs.stringify({flowType: 'i', obussId: this.ofoItemId})).then(res => {
          if(res.data.code == 200) {
            this.$router.push('/share')
          }
        })
      },
      // 点击组管理按钮
      clickGroupBtn() {
        this.dialogTableVisible = true
        this.getGroupList()
      },
      // 获取组列表
      getGroupList() {
        this.$http.get(url.getGroupList, {params: {
          groupType: 's',
          pageSize: 1000
        }}).then(res => {
          this.groupList = res.data.data.list
          this.groupList.forEach(item1 => {
            item1.bussIds = item1.bussIds.split(',')
            item1.bussIdsName = []
            this.userList.forEach(item => {
              if(item.ofrThingList.length) {
                item.ofrThingList.forEach(val => {
                  item1.bussIds.forEach(el => {
                    if(el == val.ofrThingId) {
                      item1.bussIdsName.push(val.name)
                    }
                  })
                })
              }
            })
            item1.bussIdsName = item1.bussIdsName.toString()
          })
        })
      },
      handleSelect(rows) {
        // this.ids = rows.map(item => item.id)
        this.ids = rows
      },
      //点击行选中或取消复选框
      handleRowClick(row, column, event){
        //通过ref绑定后这里使用$refs.table来操作bom元素
        this.$refs.groupTable.toggleRowSelection(row);
      },
      // 确定组
      confirmGroup() {
        this.form.toUserIds = this.ids[0].bussIds
        this.changeSelect(this.form.toUserIds)
        this.dialogTableVisible = false
      },
      // 新增组按钮
      handleAddGroup() {
        this.groupForm.bussIds = []
        this.groupForm.name = ''
        this.groupForm.id = ''
        this.$nextTick(() => {
          // 清除表单校验效果
          this.$refs.groupForm.clearValidate();
        })
        // this.$refs['groupForm'].resetFields();
        this.dialogGroupVisible = true
      },
      // 修改组
      handleEditGroup(row) {
        this.groupForm = Object.assign({}, row)
        // this.groupForm.bussIds = this.groupForm.bussIds.split(',')
        this.dialogGroupVisible = true
      },
      // 删除组
      handleDelGroup(row) {
        let id = row.id || this.ids.toString()
        this.$confirm('此操作将永久删除该组, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url.removeGroup, qs.stringify({id})).then(res => {
            if(res.data.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getGroupList()
            }
          })
          
        }).catch(() => {        
        });
      },
      // 新增/修改组
      submitGroup(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isLoading = true
            let obj = Object.assign({}, this.groupForm)
            obj.bussIds = obj.bussIds.toString()
            obj.groupType = 's'
            // console.log(obj);
            if(!obj.id) {
              this.$http.post(url.addGroup, qs.stringify(obj)).then(res => {
                // console.log(res);
                this.isLoading = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg);
                  this.dialogGroupVisible = false;
                  this.getGroupList()
                }
              })
            }else {
              this.$http.post(url.editGroup, qs.stringify(obj)).then(res => {
                // console.log(res);
                this.isLoading = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg);
                  this.dialogGroupVisible = false;
                  this.getGroupList()
                }
              })
            }
          } else {
            return false;
          }
        });
      },
      selectAll(val) {
        this.userList.forEach(item => {
          item.checked = val
          this.selectGroupAll(val, item.ofrResourceId)
        })
      },
      selectGroupAll(val, id){
        const arr = this.userList.find(f => f.ofrResourceId == id).ofrThingList.map(m => m.ofrThingId)
        if(val){
          arr.forEach(item => {
            if(!this.form.toUserIds.includes(item)){
              this.form.toUserIds.push(item + '')
            }
          })
        }else {
          this.form.toUserIds.forEach((item,index)=>{
            if(arr.includes(item)){
              this.form.toUserIds.splice(index,1,'')
            }
          })
        }
        this.form.toUserIds = this.form.toUserIds.filter(f => f != '')
        this.checked = this.userList.every((v) => {
          return v.checked
        })
      },
      changeSelect(val){
        this.userList.forEach(item => {
          const arr = item.ofrThingList.map(m => m.ofrThingId)
          item.checked = arr.every((v) => {
            return val.some(s => s == v)
          })
        })
        this.checked = this.userList.every((v) => {
          return v.checked
        })
      },
      changeCopy(ofoDeal, ofoFlowWork) {
        // console.log(ofoDeal);
        // console.log(ofoFlowWork);
        this.ofoDeal = ofoDeal
        // this.ofoDealId = this.ofoDeal.ofoDealId + ''
        this.ofoFlowWork = ofoFlowWork
        // this.ofoFlowWorkId = this.ofoFlowWork.ofoFlowWorkId + ''
      },
      isShowToltip (e) {
        const bool = textRange(e.target)
        this.isShowText = bool
      },
      hideTip () {
        // console.log(11);
        this.isShowText = false
      },
      querySearch(queryString, cb) {
        var restaurants = this.restaurants;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.value.toLowerCase().includes(queryString.toLowerCase()));
        };
      },
      // 分享收藏
      handleCollection(item, type) {
        this.loading = true
        if(type == 's') { // 分享
          this.dialogShareVisible = true
          this.form.toUserIds = []
          this.form.obussId = item.ofoItemId
          if(!this.userList.length) {
            // 获取关联人员列表
            this.$http.get(url.getPostAndUserList).then(res => {
              if(res.data.code == 200) {
                this.userList = res.data.data
                this.userList.forEach(item => {
                  if(item.ofrThingList.length) {
                    item.checked = false
                  }else {
                    item.checked = true
                  }
                })
              }
            })
          }else {
            this.userList.forEach(item => {
              if(item.ofrThingList.length) {
                item.checked = false
              }else {
                item.checked = true
              }
            })
          }
          this.$http.get(url.getShareToList, {params: {flowType: 'i', obussId: this.ofoItemId}}).then(res => {
            if(res.data.code == 200) {
              this.loading = false
              this.shareList = res.data.data
            }
          })
        }else { // 收藏
          if(item.isCollect == '1') {
            this.$http.post(url.shareCollect, qs.stringify({
              bussType: 'c',
              flowType: 'i',
              obussId: item.ofoItemId
            })).then(res => {
              if(res.data.code == 200) {
                this.$message.success('收藏成功')
                this.allData.isCollect = '0'
              }
            })
          }else {
            this.$http.post(url.cancelShareCollect, qs.stringify({
              bussType: 'c',
              flowType: 'i',
              obussId: item.ofoItemId
            })).then(res => {
              if(res.data.code == 200) {
                this.$message.success('取消收藏')
                this.allData.isCollect = '1'
              }
            })
          }
          
        }
      },
      // 取消分享
      handleCancel(row, index) {
        this.$confirm('此操作将取消分享?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url.cancelShareCollect, qs.stringify({
            bussType: 's',
            flowType: 'i',
            obussId: row.obussId,
            toUserId: row.toUserId
          })).then(res => {
            if(res.data.code == 200) {
              this.$message.success('取消分享')
              this.shareList.splice(index, 1)
            }
          })
        }).catch(() => {
                  
        });
      },
      // 分享操作
      submitShare() {
        this.$http.post(url.shareCollect, qs.stringify({
          bussType: 's',
          flowType: 'i',
          obussId: this.form.obussId,
          toUserIds: this.form.toUserIds.toString()
        })).then(res => {
          if(res.data.code == 200) {
            this.$message.success('分享成功')
            this.dialogShareVisible = false
          }
        })
      },
      inquire(item) {
        // console.log(item);
        // this.$refs.activity[0].loading = true
        // this.$refs.activity[0].pageNum = 1
        // if(item.restype1Kind == '6') {
        //   this.siftForm.dealId = ''
        // }else {
        //   this.siftForm.dealowhId = ''
        // }
        // this.$refs.activity[0].getList()
      },
      reset() {
        // this.siftForm.baseId = ''
        // this.siftForm.codename = ''
        // this.siftForm.dealId = ''
        // this.siftForm.dealowhId = ''
        // this.$refs.activity[0].loading = true
        // this.$refs.activity[0].pageNum = 1
        // this.$refs.activity[0].getList()
      },
      handleClickConfig(tab, event) {
        this.activeNameConfig = this.allData.ofoFlowDealList[tab.index].ofoDealId + '';
        this.tableData = this.allData.ofoFlowDealList[tab.index].ofoFlowWorkList
      },
      // 获取上级列表
      getWorkCodeList(item) {
        if(item.beforework && item.beforeworkName) {
          this.$http.get(url.getWorkCodeList, {params: {
            ofoflowworkId: item.ofoFlowWorkId
          }}).then(res => {
            if(res.data.code == 200) {
              // this.restaurants = res.data.data
              this.restaurants = res.data.data.map(item => {
                return {
                  value: `${item.name} - ${item.code}`,
                  ofoWorkId: item.ofoWorkId,
                  code: item.code
                }
              })
            }
          })
        }else {
          this.restaurants = []
        }
        
      },
      // 切换交易
      changeDeal(tab) {
        this.siftForm.baseId = ''
        this.siftForm.codename = ''
        this.siftForm.dealId = ''
        this.siftForm.dealowhId = ''
        this.$store.commit('setJumpData', {})
        this.ofoDeal = this.allData.ofoFlowDealList[tab.index];
        this.ofoDealId = this.ofoDeal.ofoDealId + ''
        this.tabIndex = tab.index
        if(this.ofoDeal.ofoFlowWorkList.length) {
          this.ofoFlowWork = this.ofoDeal.ofoFlowWorkList[0]
          this.ofoFlowWorkId = this.ofoFlowWork.ofoFlowWorkId + ''
          this.getResData(this.ofoDeal, this.ofoFlowWork)
          this.getWorkCodeList(this.ofoFlowWork)
        }else {
          this.ofoFlowWorkId = ''
        }
      },
      // 切换流程
      changeWork(tab) {
        this.siftForm.baseId = ''
        this.siftForm.codename = ''
        this.siftForm.dealId = ''
        this.siftForm.dealowhId = ''
        // 由审批跳过来的
        if(JSON.stringify(this.data) != "{}") {
          // console.log(1111);
          this.allData.ofoFlowDealList.forEach((item, index) => {
            if(this.ofoDealId == item.ofoDealId) {
              this.tabIndex = index
            }
          })
        }
        // // console.log(tab);
        this.$store.commit('setJumpData', {})
        // this.ofoFlowWorkId = this.allData.ofoFlowDealList[this.tabIndex].ofoFlowWorkList[tab.index].ofoFlowWorkId + ''
        this.ofoFlowWork = this.ofoDeal.ofoFlowWorkList[tab.index]
        this.ofoFlowWorkId = this.ofoFlowWork.ofoFlowWorkId + ''
        // // console.log(this.ofoFlowWorkId);
        this.getResData(this.ofoDeal, this.ofoFlowWork)
        this.getWorkCodeList(this.ofoFlowWork)
      },
      clickOfoFlowWork(data) {
        this.siftForm.baseId = ''
        this.siftForm.codename = ''
        this.siftForm.dealId = ''
        this.siftForm.dealowhId = ''
        this.configIndex = '1'
        this.tabIndex = data.dealIndex,
        this.ofoDeal = data.ofoDeal,
        this.ofoDealId = data.ofoDeal.ofoDealId + ''
        this.ofoFlowWorkId = data.ofoFlowWork.ofoFlowWorkId + ''
        this.ofoFlowWork = data.ofoFlowWork
        this.getResData(this.ofoDeal, this.ofoFlowWork)
        this.getWorkCodeList(this.ofoFlowWork)
      },
      // 配置更新
      handleRefresh() {
        this.$confirm('此操作将更新配置, 是否更新?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url.updateItemConfig, qs.stringify({ofoItemId: this.ofoItemId})).then(res => {
          // console.log(res);
          if(res.data.code == 200){
            this.$message.success(res.data.msg)
            this.loadData(true)
          }
        })
        }).catch(() => { 
        });
      },
      // 获取甲方资源数据
      getResData(item, val) {
        if(item.restype1 && item.restype1Name && item.restype1Kind == '6') {
          this.$http.get(url.getDeptAndUser).then(res => {
            this.departmentList = res.data.data
          })
        }else if(item.restype1 && item.restype1Name && item.restype1Kind != '6') {
          // this.$http.get(url.getResTypeAndList, {params: {cbRestypeId: item.restype1}}).then(res => {
          //   this.resourceList = res.data.data.list
          // })
          this.$http.get(url.getDealList, {params: {ofoflowworkId: val.ofoFlowWorkId}}).then(res => {
            this.resourceList = res.data.data
          })
        }
      },
      // 发送请求
      loadData(isUpdateConfig) {
        this.$http.get(url.getItemDetail, {params: {
          ofoItemId: this.ofoItemId,
          isShare: this.isShare
        }}).then(res => {
          if(res.data.code == 200) {
            this.allData = res.data.data
            this.qrcode()
            // if(this.allData.ofoFlowDealList) {
            //   this.allData.ofoFlowDealList.formEach(item => {
            //     if(item.ofoFlowWorkList.length) {
            //       item.ofoFlowWorkList.forEach(val => {

            //       })
            //     }
            //   })
            // }
            this.allData.dimeList =  this.handleFormList(this.allData.dimeList)
            if(JSON.stringify(this.data) == "{}") {
              if(this.allData.ofoFlowDealList) {
                this.ofoDeal = this.allData.ofoFlowDealList[0]
                this.ofoDealId = this.activeNameConfig = this.ofoDeal.ofoDealId + ''
                if(this.ofoDeal.ofoFlowWorkList.length) {
                  this.ofoFlowWork = this.ofoDeal.ofoFlowWorkList[0]
                  this.ofoFlowWorkId = this.ofoFlowWork.ofoFlowWorkId + ''
                  if(isUpdateConfig) {
                    // console.log(this.$refs.activity);
                    this.$refs.activity[0].getItemWorkConfig(this.ofoFlowWork)
                  }
                  this.getWorkCodeList(this.ofoFlowWork)
                  this.getResData(this.ofoDeal, this.ofoFlowWork)
                }
              }
            }else { // 由审批跳转过来的
              this.ofoDealId = this.data.ofoDealId + ''
              this.ofoFlowWorkId = this.data.ofoFlowWorkId + ''
              if(this.allData.ofoFlowDealList) {
                this.allData.ofoFlowDealList.forEach(item => {
                  if(item.ofoDealId == this.ofoDealId) {
                    this.ofoDeal = item
                  }
                  if(item.ofoFlowWorkList.length) {
                    item.ofoFlowWorkList.forEach(val => {
                      if(val.ofoFlowWorkId == this.ofoFlowWorkId) {
                        this.ofoFlowWork = val
                        this.getWorkCodeList(val)
                        this.getResData(item, val)
                      }
                    })
                    
                  }
                })
              }
            }
            if(this.allData.ofoFlowDealList) {
              this.activeNameConfig = this.allData.ofoFlowDealList[0].ofoDealId + ''
              this.tableData = this.allData.ofoFlowDealList[0].ofoFlowWorkList
            }
            
          }
          
        })
        // 获取交易类型下拉
        if(!this.dropDown.kindDealType){
          this.$http.get(url.getDataList, {params: {type: 'buss_kind_dealType'}}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setKindDealType', res.data.data)
            }
          })
        }
        // 获取流程发布类型下拉
        if(!this.dropDown.publishType){
          this.$http.get(url.getDataList, {params: {type: 'flow_publish_type'}}).then(res => {
            this.$store.commit('setPublishType', res.data.data)
          })
        }
        // 获取账套类型下拉
        if(!this.dropDown.subjectAccountType){
          this.$http.get(url.getDataList, {params: {type: 'buss_subject_account_type'}}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setSubjectAccountType', res.data.data)
            }
          })
        }
        // 获取凭证类型下拉
        if(!this.dropDown.ojdtType){
          this.$http.get(url.getDataList, {params: {type: 'buss_ojdt_type'}}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setOjdtType', res.data.data)
            }
          })
        }
        // 获取核算方式类型下拉
        if(!this.dropDown.costmanangeType){
          this.$http.get(url.getDataList, {params: {type: 'buss_costmanage_type'}}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setCostmanangeType', res.data.data)
            }
          })
        }
        // 获取核算对象类型下拉
        if(!this.dropDown.flowWorkkindmanageType){
          this.$http.get(url.getDataList, {params: {type: 'flow_workkindmanage_type'}}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setFlowWorkkindmanageType', res.data.data)
            }
          })
        }
        // 获取报表类型下拉
        if(!this.dropDown.subdealType){
          this.$http.get(url.getDataList, {params: {type: 'buss_subdeal_type'}}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setSubdealType', res.data.data)
            }
          })
        }
        // 获取库存类型下拉
        if(!this.dropDown.stockacctType){
          this.$http.get(url.getDataList, {params: {type: 'buss_stockacct_type'}}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setStockacctType', res.data.data)
            }
          })
        }
        // 获取复制类型下拉
        if(!this.dropDown.workcopyType){
          this.$http.get(url.getDataList, {params: {type: 'buss_workcopy_type'}}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setWorkcopyType', res.data.data)
            }
          })
        }
        // 获取活动附件类型下拉
        // if(!this.dropDown.workFiletype){
        //   this.$http.get(url.getDataList, {params: {type: 'buss_work_filetype'}}).then(res => {
        //     this.$store.commit('setWorkFiletype', res.data.data)
        //   })
        // }
        // 获取内容维度类型下拉
        if(!this.dropDown.thingDimeType){
          this.$http.get(url.getDataList, {params: {type: 'buss_thing_dimeType'}}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setThingDimeType', res.data.data)
            }
          })
        }
        // 获取发布类型下拉
        if(!this.dropDown.reletype){
          this.$http.get(url.getDataList, {params: {type: 'buss_reletype'}}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setReletype', res.data.data)
            }
          })
        }
        // 获取移动端是否能编辑下拉
        if(!this.dropDown.appEdit){
          this.$http.get(url.getDataList, {params: {type: 'buss_app_edit'}}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setAppEdit', res.data.data)
            }
          })
        }
        // 获取是否下拉
        if(!this.dropDown.yesNo){
          this.$http.get(url.getDataList, {params: {type: 'buss_yes_no'}}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setYesNo', res.data.data)
            }
          })
        }
        // 获取计算方式下拉
        if(!this.dropDown.settleType){
          this.$http.get(url.getDataList, {params: {type: 'buss_settle_type'}}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setSettleType', res.data.data)
            }
          })
        }
        // 获取核销类型下拉
        if(!this.dropDown.checkType){
          this.$http.get(url.getDataList, {params: {type: 'buss_check_type'}}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setCheckType', res.data.data)
            }
          })
        }
      }
    },
    inject: ["reload"], //注入   和methods同级
    watch: {
      $route(to,from) {
        // console.log(to);
        // console.log(from);
        this.reload()
      },
      toWork(newValue, oldValue) {
        // console.log(this.toWork)
        // console.log(111);
        if(this.toWork.ofoWorkId || this.toWork.ofoItemId) {
          this.data = this.toWork
          // this.loadData()
          this.ofoDealId = this.data.ofoDealId + ''
          this.ofoFlowWorkId = this.data.ofoFlowWorkId + ''
          if(this.allData.ofoFlowDealList) {
            this.allData.ofoFlowDealList.forEach(item => {
              if(item.ofoDealId == this.ofoDealId) {
                this.ofoDeal = item
              }
              if(item.ofoFlowWorkList.length) {
                item.ofoFlowWorkList.forEach(val => {
                  if(val.ofoFlowWorkId == this.ofoFlowWorkId) {
                    this.ofoFlowWork = val
                    this.getWorkCodeList(val)
                    this.getResData(item, val)
                  }
                })
                
              }
            })
          }
        }
      },
      ofoItemId(newValue, oldValue) {
        // console.log(newValue)
      }
    },
  }
</script>

<style scoped lang="scss">
::v-deep .my-kqoption {
  width: auto !important;
}
.detail {
  padding: 16px 16px 0 16px;
  .modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 3000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    >img {
      width: 400px;
      height: 400px;
      cursor: pointer;
    }
  }
  .main {
    background: #FFFFFF;
    box-shadow: 2px 3px 8px 0px rgba(208,208,208,0.5);
    border-radius: 11px;
    padding: 16px;
    position: relative;
    margin-bottom: 16px;
    .el-col {
    display: flex;
    /* table-layout: fixed; */
    position: relative;
    span {
      font-size: 14px;
      margin-bottom: 10px;
    }
    .text1 {
      min-width: 40px;
      font-size: 14px;
      margin-bottom: 10px;
    }
    .text2 {
      font-size: 14px;
      margin-bottom: 10px;
      /* width: calc(100% - 80px); */
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .extend {
      position: absolute;
      top: 0;
      left: 15px;
      z-index: 99;
      padding: 0;
      border: 0;
    }
  }
    .change {
      position: absolute;
      top: 47px;
      right: 0;
      z-index: 99;
      border: 0;
    }
    .content {
      .line {
        display: flex;
        margin-bottom: 20px;
        position: relative;
        .left {
          color: black;
          /* background-color: #0C75E5; */
          padding: 6px 10px;
          margin-right: 16px;
          border: #0C75E5 solid 1px;
        }
        .left::after {
          content: '';
          position: absolute;
          top: 33px;
          left: 24px;
          border: 10px solid rgba($color: #000000, $alpha: 0);
          /* border-top: 10px solid #0C75E5; */
        }
        .right {
          >span {
            display: inline-block;
            padding: 6px 10px;
            margin-right: 16px;
            background-color: pink;
          }
        }
      }
    }
  }
  .header {
    font-size: 17px;
    text-align: center;
    margin-bottom: 16px;
    overflow: hidden;
    width: 94%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .refresh {
    position: absolute;
    top: 56px;
    right: 16px;
    border: 0;
    z-index: 99;
    /* background: #F2F4F5; */
  }
  .deal {
    display: flex;
    margin-bottom: 16px;
    .deal-item {
      padding: 6px 16px 6px 32px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #282828;
      line-height: 20px;
      box-sizing: content-box;
      background-color: #F2F2F2;
      position: relative;
      margin-right: 16px;
      cursor: pointer;
    }
    .deal-item::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border: 16px solid rgba($color: #000000, $alpha: 0);
      border-left: 16px solid #fff;
    }
    .deal-item::after {
      content: '';
      position: absolute;
      top: 0;
      right: -32px;
      border: 16px solid rgba($color: #000000, $alpha: 0);
      border-left: 16px solid #F2F2F2;
    }
    .deal-isActive {
      color: #fff;
      background-color: #0C75E5;
    }
    .deal-isActive::after {
      border-left: 16px solid #0C75E5;
    }
  }
  .work {
    display: flex;
    .work-left {
      margin-right: 10px;
      .work-item {
        background: #FFFFFF;
        border: 1px solid #0C75E5;
        margin-bottom: 16px;
        padding: 6px 10px;
        text-align: center;
        margin-right: 0;
        position: relative;
        cursor: pointer;
      }
      .work-isActive {
        color: #fff !important;
        background-color: #0C75E5 !important;
      }
    }
    .work-right {
      flex: 1;
    }
  }
  .tabs1 {
    ::v-deep >.el-tabs__header {
      border: 0;
      /* padding-bottom: 16px; */
      margin: 0;
      /* border-bottom: 1px solid #E8E8E8; */
      margin-bottom: 16px;
    }
    ::v-deep .el-tabs__item {
      border: 0;
      padding: 6px 16px 6px 32px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #282828;
      line-height: 20px;
      box-sizing: content-box;
      background-color: #F2F2F2;
      position: relative;
      margin-right: 16px;
    }
    ::v-deep .el-tabs__item::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border: 16px solid rgba($color: #000000, $alpha: 0);
      border-left: 16px solid #fff;
    }
    ::v-deep .el-tabs__item::after {
      content: '';
      position: absolute;
      top: 0;
      right: -32px;
      border: 16px solid rgba($color: #000000, $alpha: 0);
      border-left: 16px solid #F2F2F2;
    }
    ::v-deep .el-tabs__nav {
      border: 0;
    }
    ::v-deep .el-tabs__item.is-active {
      color: #fff;
      background-color: #0C75E5;
    }
    ::v-deep .el-tabs__item.is-active::after {
      border-left: 16px solid #0C75E5;
    }
    .tabs2 {
      ::v-deep .el-tabs__header {
        border: 0;
        margin-right: 30px;
      }
      ::v-deep .el-tabs__item {
        background: #FFFFFF !important;
        border: 1px solid #0C75E5 !important;
        padding: 6px 10px !important;
        text-align: center !important;
        position: relative !important;
        margin: 8px 10px 16px 0 !important;
      }
      ::v-deep .el-tabs__item::before {
        border: 0 !important;
      }
      ::v-deep .el-tabs__item::after {
        border: 0 !important;
      }
      ::v-deep .el-tabs__item.is-active {
        color: #fff !important;
        background-color: #0C75E5 !important;

      }
    }
  }
  .title {
    height: 14px;
    font-size: 14px;
    font-family: STHeitiSC-Medium, STHeitiSC;
    font-weight: 500;
    color: #282828;
    line-height: 14px;
    padding-left: 8px;
    border-left: #0c75e5 2px solid;
    margin-bottom: 16px;
    font-weight: bolder;
  }
}
::v-deep .el-dialog__body {
  max-height: calc(100vh - 295px);
  overflow: auto;
}
</style>