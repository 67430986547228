import handleTree from "@/utils/handleTree.js"
export default function handleWorkConfigList(checkConfig, list, workConfig) {
  // console.log(checkConfig);
  // console.log(list);
  // console.log(workConfig);
  if(checkConfig && workConfig && JSON.stringify(workConfig) != "{}") {
    let workConfigList = [...JSON.parse(JSON.stringify(workConfig.workConfigList1)), ...JSON.parse(JSON.stringify(workConfig.workConfigList2)), ...JSON.parse(JSON.stringify(workConfig.workConfigList3)), ...JSON.parse(JSON.stringify(workConfig.workConfigList4))]
    list.forEach(item => {
      item.workConfigList1 = []
      item.workConfigList2 = []
      item.workConfigList3 = []
      item.workConfigList4 = []
      if(item.configValueList) {
        for(let i = 0;i < workConfigList.length;i++) {
          const index = item.configValueList.findIndex((val) => {return val.ofoFlowWorkConfigId == workConfigList[i].ofoFlowWorkConfigId})
          if(index == -1) {
            if(workConfigList[i].configType == '1') {
              item.workConfigList1.push(JSON.parse(JSON.stringify(workConfigList[i])))
            }else if(workConfigList[i].configType == '2') {
              item.workConfigList2.push(JSON.parse(JSON.stringify(workConfigList[i])))
            }else if(workConfigList[i].configType == '3') {
              item.workConfigList3.push(JSON.parse(JSON.stringify(workConfigList[i])))
            }else {
              item.workConfigList4.push(JSON.parse(JSON.stringify(workConfigList[i])))
            }
          }else {
            if(workConfigList[i].configType == '1') {
              item.workConfigList1.push(
                {
                  ...JSON.parse(JSON.stringify(workConfigList[i])),
                  ofoWorkConfigValueId: item.configValueList[index].ofoWorkConfigValueId,
                  configValue: item.configValueList[index].configValue,
                })
            }else if(workConfigList[i].configType == '2') {
              item.workConfigList2.push(
                {
                  ...JSON.parse(JSON.stringify(workConfigList[i])),
                  ofoWorkConfigValueId: item.configValueList[index].ofoWorkConfigValueId,
                  configValue: item.configValueList[index].configValue,
                })
            }else if(workConfigList[i].configType == '3') {
              item.workConfigList3.push(
                {
                  ...JSON.parse(JSON.stringify(workConfigList[i])),
                  ofoWorkConfigValueId: item.configValueList[index].ofoWorkConfigValueId,
                  configValue: item.configValueList[index].configValue,
                })
            }else {
              item.workConfigList4.push(
                {
                  ...JSON.parse(JSON.stringify(workConfigList[i])),
                  ofoWorkConfigValueId: item.configValueList[index].ofoWorkConfigValueId,
                  configValue: item.configValueList[index].configValue,
                })
            }
          }
        }
      }else {
        item.workConfigList1 = JSON.parse(JSON.stringify(workConfig.workConfigList1))
        item.workConfigList2 = JSON.parse(JSON.stringify(workConfig.workConfigList2))
        item.workConfigList3 = JSON.parse(JSON.stringify(workConfig.workConfigList3))
        item.workConfigList4 = JSON.parse(JSON.stringify(workConfig.workConfigList4))
      }
    })
  }else {
    list.forEach(item => {
      item.workConfigList1 = []
      item.workConfigList2 = []
      item.workConfigList3 = []
      item.workConfigList4 = []
    })
  }
  // list.forEach((item, index) => {

  //   item.bomId = item.bomId ? `175${item.bomId}` : null
  //   item.pid = item.pid ? `175${item.pid}` : null
  // })
  let arr = handleTree(list, 'bomId', 'pid')
  getLevel(arr, 1)

  return arr
}
function getLevel(list, level) {
  list.forEach(item => {
    item.level = level
    if(item.children && item.children.length) {
      getLevel(item.children, item.level + 1)
    }
  })
}
