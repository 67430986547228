<template>
  <div class="tab1">
    <!-- <el-form :inline="true" :model="thingFormInline" class="demo-form-inline" size="small" @submit.native.prevent>
      <el-form-item>
        <strong slot="label">名称</strong>
        <el-input v-model="thingFormInline.name" placeholder="请输入名称" clearable @keyup.enter.native="inquire" @clear="inquire"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="inquire" style="background:#0C75E5">查询</el-button>
        <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form> -->
    <el-button type="primary" plain size="small" icon="el-icon-plus" @click="handleAdd({bomId: 0})" v-if="judgeButton('add')">新增</el-button>
    <el-button type="info" plain size="small" icon="el-icon-copy-document" @click="handleCopy" v-if="judgeButton('add')">复制</el-button>
    <!-- <el-button type="success" plain size="small" icon="el-icon-edit" :disabled="!edit" @click="handleEdit">编辑</el-button>
    <el-button type="danger" plain size="small" icon="el-icon-delete" :disabled="!del" @click="handleDel">删除</el-button> -->
    <el-table
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%;margin-top:16px"
      @selection-change="handleSelectionChange"
      :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
      v-loading="loading"
      row-key="bomId"
      default-expand-all
      :tree-props="{children: 'children'}">
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column
        type="index"
        width="55"
        label="行号">
      </el-table-column>
      <el-table-column
        prop="name"
        label="资源名称">
        <!-- <template slot-scope="scope">
          <a @click="handleView(scope.row)" class="link">{{scope.row.name}}</a>
        </template> -->
      </el-table-column>
      <el-table-column
        label="数量"
        prop="quantity"
        width="100">
      </el-table-column>
      <el-table-column
        label="金额"
        prop="price"
        width="150">
        <template slot-scope="scope">
          {{scope.row.price | comdify('no')}}
        </template>
      </el-table-column>
      <el-table-column
        label="描述"
        prop="remark"
        width="200"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        label="操作"
        fixed="right"
        width="170">
        <template slot-scope="scope">
          <el-button
            size="mini" icon="el-icon-plus" type="text"
            @click="handleAdd(scope.row)" v-if="judgeButton('add')">新增</el-button>
          <el-button
            size="mini" icon="el-icon-edit" type="text"
            @click="handleEdit(scope.row)" v-if="judgeButton('edit')">编辑</el-button>
          <el-button
            size="mini" icon="el-icon-delete" type="text"
            @click="handleDel(scope.row)" v-if="judgeButton('delete')">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
        style="max-height: 50vh; overflow-y: auto;overflow-x:hidden"
        label-width="60px"
      >
        <el-form-item label="资源" prop="ofrResourceId">
          <el-select v-model="ruleForm.ofrResourceId" placeholder="请选择资源" filterable style="width: 100%">
            <el-option-group
              v-for="(group, index) in resTreeList"
              :key="index"
              :label="group.name">
              <el-option
                v-for="item in group.resourceList"
                :key="item.ofrResourceId"
                :label="item.remarks"
                :value="item.ofrResourceId">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item label="父级" prop="pid">
          <treeselect v-model="ruleForm.pid" :options="options" :normalizer="normalizer" placeholder="请选择父级" />
        </el-form-item>
        <el-form-item label="金额" prop="price">
          <el-input
            v-model="ruleForm.price"
            :placeholder="`请输入金额`"
          ></el-input>
        </el-form-item>
        <el-form-item label="数量" prop="quantity">
          <el-input
            v-model="ruleForm.quantity"
            :placeholder="`请输入数量`"
          ></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="remark">
          <el-input
            v-model="ruleForm.remark"
            :placeholder="`请输入描述`"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="isLoading">确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="复制BOM"
      :visible.sync="dialogCopyVisible"
      :append-to-body="true"
      width="30%"
    >
      <el-form
        :model="formCopy"
        :rules="rules"
        ref="formCopy"
        class="demo-ruleForm"
        style="max-height: 50vh; overflow-y: auto;overflow-x:hidden"
        label-width="60px"
      >
        <el-form-item label="资源" prop="ofrResourceId">
          <el-select v-model="formCopy.ofrResourceId" placeholder="请选择资源" filterable style="width: 100%">
            <el-option-group
              v-for="(group, index) in copyFormResources"
              :key="index"
              :label="group.name">
              <el-option
                v-for="item in group.resourceList"
                :key="item.ofrResourceId"
                :label="item.remarks"
                :value="item.ofrResourceId">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogCopyVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitCopyForm('formCopy')" :loading="isLoading">确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import url from '@/config/url'
import qs from 'qs'
import {mapState} from 'vuex'
import DictTag from '@/components/DictTag.vue'
import DimeTag from '@/components/DimeTag.vue'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
  export default {
    props: {
      contentData: {
        type: Object,
        default: {}
      }
    },
    components: {
      Treeselect
    },
    computed: {
      ...mapState({
        dropDown: state => state.dropDown.dropDown,
        currentMenu: (state) => state.tags.currentMenu,
      })
    },
    data() {
      return {
        dialogFormVisible: false,
        ruleForm: {},
        formCopy: {},
        rules: {
          ofrResourceId: [{ required: true, message: '请选择资源', trigger: "change" }]
        },
        // 弹出层标题
        title: '',
        loading: true,
        tableData: [],
        isLoading: false,
        resTreeList: [],
        // 树选项
        options: [],
        dialogCopyVisible: false,
        copyFormResources: []
      }
    },
    mounted() {
      this.getList()
      this.getResTypeResource()
    },
    methods: {
      judgeButton(type) {
        return this.currentMenu.perms.some(item => item.menuType == 'F' && item.perms == `${this.currentMenu.kind}:${type}`)
      },
      getResTypeResource() {
        this.$http.get(url.resTypeResource).then(res => {
          // console.log(res);
          if(res.data.code == 200) {
            this.resTreeList = res.data.data
          }
        })
      },
      // 拿资源维度列表
      getList() {
        this.loading = true
        this.options = []
        this.$http.get(url.bomResource + this.contentData.ofrResourceId).then(res => {
          this.tableData = this.$handleTree(res.data.data, 'bomId', 'pid')
          const data = {bomId: 0, name: '顶级节点', children: []};
          data.children = this.tableData;
          this.options.push(data)
          this.loading = false
          // console.log(JSON.parse(JSON.stringify(this.tableData)));
        })
      },
      /** 转换数据结构 */
      normalizer(node) {
        // this.$nextTick(() => {
          if (node.children && !node.children.length) {
            delete node.children;
          }
          return {
            id: node.bomId,
            label: node.name,
            children: node.children
          };
        // })
      },
      // inquire() {
      //   this.pageNum = 1
      //   this.getList()
      // },
      reset() {
        this.ruleForm = {
          ofrResourceId: null,
          pid: 0,
          price: null,
          quantity: null,
          remark: null,
          rid: this.contentData.ofrResourceId
        }
      },
      // 表格选中框事件
      handleSelectionChange(rows) {
        this.ids = rows.map(item => item.bomId)
        // this.edit = rows.length === 1
        // this.del = rows.length
      },
      // handleView(row) {
      //   // console.log(row);
      //   this.viewTitle = row.name
      //   this.dialogViewVisible = true
      //   this.$http.get(url.getOfrThing, {params: {ofrThingId: row.ofrThingId}}).then(res => {
      //     if(res.data.code === 200) {
      //       // console.log(res.data);
      //       this.ruleForm = res.data.data.ofrThing
      //       this.formList = res.data.data.dimensionList
      //       this.ruleForm.dataSourceConfig = this.formList
      //     }
      //   })
      // },
      // 表格的修改按钮
      handleEdit(row) {
        if(this.$refs.ruleForm) {
          this.$nextTick(() => {
            // 清除表单校验效果
            this.$refs.ruleForm.clearValidate();
          })
        }
        const id = row.bomId || this.ids[0]
        this.title = `修改BOM`
        this.dialogFormVisible = true
        this.$http.get(url.bomDetail + id).then(res => {
          if(res.data.code === 200) {
            this.ruleForm = res.data.data
          }
        })
      },
      // 表格的删除按钮
      handleDel(row) {
        // this.selected = row
        const ids = row.bomId || this.ids.toString()
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url.removeBomResource + ids).then(res => {
            if(res.data.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getList()
            }
          })

        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isLoading = true
            // console.log(this.ruleForm);
            // let dimes = []
            // if(this.formList.length != 0) {
            //   dimes = this.handleDimes(this.formList)
            // }
            if(!this.ruleForm.bomId) {
            //   // console.log(1111);
              this.$http.post(url.addBomResource, this.ruleForm).then(res => {
                // console.log(res);
                this.isLoading = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg)
                  this.dialogFormVisible = false
                  this.getList()
                }
              })
            }else {
              // console.log(22222222222);
              this.$http.post(url.editBomResource, this.ruleForm).then(res => {
                this.isLoading = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg)
                  this.dialogFormVisible = false
                  this.getList()
                }
              })
            }
          } else {
            return false;
          }
        });
      },
      submitCopyForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isLoading = true
            this.$http.post(url.copyBom, this.formCopy).then(res => {
              // console.log(res);
              this.isLoading = false
              if(res.data.code === 200) {
                this.$message.success(res.data.msg)
                this.dialogCopyVisible = false
                this.getList()
              }
            })
          } else {
            return false;
          }
        });
      },
      handleAdd(row) {
        this.title = `新增Bom`
        this.$nextTick(() => {
          // 清除表单校验效果\
          this.$refs.ruleForm.clearValidate();
        })
        this.dialogFormVisible = true
        this.reset()
        if(row) {
          this.ruleForm.pid = row.bomId
        }
      },
      handleCopy() {
        this.$nextTick(() => {
        // console.log(this.$refs);
          // 清除表单校验效果\
          if(this.$refs.formCopy) {
            this.$refs.formCopy.clearValidate();
          }
        })
        this.dialogCopyVisible = true
        this.formCopy = {
          ofrResourceId: "",
          rid: this.contentData.ofrResourceId
        }
        this.$http.get(url.copyBomResources).then(res => {
          // console.log(res);
          if(res.data.code === 200) {
            this.copyFormResources = res.data.data
          }
        })
      },
      // handleSizeChange(val) {
      //   this.pageSize = val
      //   this.pageNum = 1
      //   this.getList()
      // },
      // handleCurrentChange(val) {
      //   this.pageNum = val
      //   this.getList()
      // },
    },
    watch: {
      contentData(newValue, oldValue) {
        // console.log(newValue);
        this.getList()
      }
    },
  }
</script>

<style lang="scss" scoped>
.tab1 {
  .el-table {
    margin-bottom: 39px;
  }
}
/* ::v-deep .el-dialog__body {
  padding: 30px 0 30px 20px;
} */
</style>
