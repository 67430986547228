<template>
  <div class="contain">
    <div class="head">
      <h2>{{cfDboard.name}}</h2>
      <div class="time">{{ time }}</div>
    </div>
    <div class="main">
      <div class="box" v-for="(item, index) in cfDboardContList" :key="index" :style="{width: cfDboard.num == 4 ? '49%' : '33%'}">
        <div class="tit">
          <span>{{ item.name }}</span>
          <p></p>
        </div>
        <!-- 柱状 -->
        <div v-if="item.type == '1'">
          <div :id="'echart' + index" class="echart" style="height: 250px"></div>
        </div>
        <!-- 折线 -->
        <div v-if="item.type == '2'">
          <div :id="'echart' + index" class="echart" style="height: 250px"></div>
        </div>
        <!-- 表格 -->
        <div v-if="item.type == '3'" class="flex">
          <el-table
            :data="item.tableData"
            :header-cell-style="{'background': 'rgba(0, 0, 0, 0.89)', 'color': '#fff', padding: '5px 0'}"
            :row-style="{'background': 'rgba(0, 0, 0, 0.9)','color': '#fff'}"
            default-expand-all
            :row-class-name="rowClass"
            row-key="id"
            :tree-props="{children: 'secondList', hasChildren: 'hasSecondList'}"
            :cell-style="{padding: '5px 0'}"
            max-height="264px">
            <!-- <el-table-column prop="name" label="名称" min-width="100"></el-table-column>
            <el-table-column v-for="(val, id) in item.cfFlowReptcontList" :key="id" :label="val.label" align="right" header-align="center" min-width="160">
              <template slot-scope="scope">
                <div v-for="(el, cfFlowReptcontId) in scope.row.list" :key="cfFlowReptcontId" v-if="el.cfFlowReptcontId == val.cfFlowReptcontId">
                  <template v-if="item.flowRepttype != '4'">
                    <div v-if="val.labelType == '4'">{{el.reptValue | comdify}}</div>
                    <div v-else-if="val.labelType == '5'">{{el.reptValue | filter_handleTimeRange}}</div>
                    <div v-else-if="el.subdealtype == '70'" style="display:flex;justify-content: flex-end">
                      <div style="margin-right: 10px">
                        出勤
                        <span style="color:blue;cursor: pointer" @click="getLogs(el)">{{el.reptValue}}</span>
                      </div>
                      <div>
                        异常
                        <span style="color:red;cursor: pointer" @click="getLogs(el)">{{el.reptValue2}}</span>
                      </div>
                    </div>
                  <div v-else>{{el.reptValue}}</div>
                  </template>
                </div>
              </template>
            </el-table-column> -->
            <el-table-column prop="name" label="名称" min-width="100" v-if="item.flowRepttype != '6' && item.flowRepttype != '7'"></el-table-column>
            <el-table-column v-for="(val, id) in item.cfFlowReptcontList" :key="id" :prop="val.prop" :label="val.label" :align="(item.flowRepttype == '6' || item.flowRepttype == '7' )? 'center' : 'right'" :min-width="item.flowRepttype == '4' ? '275' : '160'">
              <template slot-scope="scope">
                <div v-if="item.flowRepttype == '6' || item.flowRepttype == '7'">
                  <template v-if="val.worktype == 'b'">{{scope.row.b[val.prop]}}</template>
                  <template v-else-if="val.worktype == 'n'">{{scope.row.n[val.prop]}}</template>
                  <template v-else-if="val.worktype == 'p'">{{scope.row.p[val.prop]}}</template>
                  <template v-else>{{scope.row.b[val.prop]}}</template>
                </div>
                <div v-for="(el, cfFlowReptcontId) in scope.row.list" :key="cfFlowReptcontId" v-if="item.flowRepttype != '6' && item.flowRepttype != '7' && el.cfFlowReptcontId == val.cfFlowReptcontId">
                  <template v-if="item.flowRepttype != '4'">
                    <div v-if="val.labelType == '4'">{{el.reptValue | comdify}}</div>
                    <div v-else-if="val.labelType == '5'">{{el.reptValue | filter_handleTimeRange}}</div>
                    <div v-else-if="el.subdealtype == '70'" style="display:flex;justify-content: flex-end">
                      <div style="margin-right: 10px">
                        出勤
                        <span style="color:blue;cursor: pointer">{{el.reptValue}}</span>
                      </div>
                      <div>
                        异常
                        <span style="color:red;cursor: pointer">{{el.reptValue2}}</span>
                      </div>
                    </div>
                    <div v-else>{{el.reptValue}}</div>
                  </template>
                  <el-table
                    v-if="item.flowRepttype == '4'"
                    :data="el.workList"
                    tooltip-effect="dark"
                    style="width: 100%"
                    :header-cell-style="{'background': 'rgba(0, 0, 0, 0.89)', 'color': '#fff', padding: '5px 0'}" 
                    :row-style="{'background': 'rgba(0, 0, 0, 0.9)','color': '#fff'}"
                    :cell-style="{padding: '5px 0'}"
                  >
                    <el-table-column prop="name" label="名称" header-align="center" min-width="100">
                      <!-- <template slot-scope="scope1"> -->
                        <!-- <a class="link" @click="handleDetail(scope1.row)" style="user-select: none;">{{scope1.row.name}}</a> -->
                      <!-- </template> -->
                    </el-table-column>
                    <el-table-column prop="ptotal" label="金额" align="right" min-width="150" header-align="center">
                      <template slot-scope="scope1">
                        {{scope1.row.ptotal | comdify}}
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 饼图 -->
        <div v-if="item.type == '4'">
          <div :id="'echart' + index" class="echart" style="height: 250px"></div>
        </div>
      </div>
      <!-- <div class="box">
        <div class="tit">
          <span>{{ orgReptList.length ? orgReptList[4].showName : '' }}</span>
          <p></p>
        </div>
        <div class="flex">
          <el-table v-if="orgReptList.length" :data="tableData4" :header-cell-style="{'background': 'rgba(0, 0, 0, 0.89)', 'color': '#fff'}" :row-style="{'background': 'rgba(0, 0, 0, 0.9)','color': '#fff'}">
            <el-table-column v-for="(item, index) in orgReptList[4].cfFlowReptcontList" :key="index" :label="item.label">
              <template slot-scope="scope">
                {{scope.row[item.prop]}}
              </template>
            </el-table-column>
          </el-table>
        </div>
        
      </div> -->
      <!-- <div class="box">
        <div class="tit">
          <span>看板3</span>
          <p></p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import url from '@/config/url'
import * as echarts from "echarts";
  export default {
    data() {
      return {
        time: null,
        orgReptList: [],
        cfDboard: {},
        cfDboardContList: [],
        echarts1: false,
        echarts67: false
      }
    },
    mounted () {
      this.getCfDboard()
      // this.getCfOrgReptList()
      this.getTime();
    },
    methods: {
      // 获取数据看板
      getCfDboard() {
        this.$http.get(url.getCfDboard).then(res => {
          if(res.data.code == 200) {
            this.cfDboard = res.data.data.cfDboard
            this.cfDboardContList = res.data.data.cfDboardContList
            if(this.cfDboardContList.some(item => item.dataSourceType == 'rept')) {
              this.getCfOrgReptList()
            }
          }
        })
      },
      async getCfOrgReptList() {
        await this.$http.get(url.getCfOrgReptList).then(res => {
          if(res.data.code == 200) {
            this.orgReptList = res.data.data
            this.cfDboardContList.forEach((item, index) => {
              // if(item.dataSourceType == 'rept') {
              let orgRept = this.orgReptList.find(val => val.cfFlowReptId == item.cforgreptId)
              item.orgRept = orgRept
              item.flowRepttype = orgRept.flowRepttype
              item.cfFlowReptcontList = orgRept.cfFlowReptcontList
              if(item.type == '1') {
                if(orgRept.flowRepttype == '1' || orgRept.flowRepttype == '2') {
                  this.$http.get(url.getOrgRept, {params: {
                    cfFlowReptId: item.cforgreptId
                  }}).then(res => {
                    if(res.data.code == 200) {
                      let xAxis = res.data.data.map(item => item.name)
                      let series = orgRept.cfFlowReptcontList.map(val => {
                        return {
                          ...val,
                          data: this.getData(val, res.data.data),
                          type: 'bar',
                          name: val.label,
                          // label: labelOption,
                          emphasis: {
                            focus: 'series'
                          },
                        }
                      })
                      this.echarts_1(xAxis, series, 'echart' + index)
                    }
                  })
                }else {
                  let xAxis = ['活动一', '活动二', '活动三', '活动四', '活动五', '活动六', '活动七']
                  let series = [
                    {
                      data: [120, 200, 150, 80, 70, 110, 130],
                      type: 'bar'
                    }
                  ]
                  this.echarts_1(xAxis, series, 'echart' + index)
                }
              }else if(item.type == '2') {
                if(orgRept.flowRepttype == '1' || orgRept.flowRepttype == '2') {
                  this.$http.get(url.getOrgRept, {params: {
                    cfFlowReptId: item.cforgreptId
                  }}).then(res => {
                    if(res.data.code == 200) {
                      let xAxis = res.data.data.map(item => item.name)
                      let series = orgRept.cfFlowReptcontList.map(val => {
                        return {
                          ...val,
                          name: val.label,
                          type: 'line',
                          data: this.getData(val, res.data.data),
                          symbol: "none"
                        }
                      })
                      this.echarts_2(xAxis, series, 'echart' + index)
                    }
                  })
                }else{
                  let xAxis = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
                  let series = [
                    {
                      name: '电脑',
                      type: 'line',
                      // stack: 'Total',
                      data: [120, 132, 101, 134, 90, 230, 210],
                      symbol: "none"
                    },
                    {
                      name: '鼠标',
                      type: 'line',
                      // stack: 'Total',
                      data: [15, 182, 191, 234, 290, 330, 500],
                      symbol: "none"
                    },
                    {
                      name: '键盘',
                      type: 'line',
                      // stack: 'Total',
                      data: [330, 232, 201, 154, 190, 330, 410],
                      symbol: "none"
                    },
                  ]
                  this.echarts_2(xAxis, series, 'echart' + index)
                }
              }else if(item.type == '3') {
                this.$http.get(url.getOrgRept, {params: {
                  cfFlowReptId: item.cforgreptId
                }}).then(res => {
                  if(res.data.code == 200) {
                    item.tableData = res.data.data
                    if(orgRept.flowRepttype != '6' && orgRept.flowRepttype != '7') {
                      item.tableData.push({
                        name: '合计',
                        list: JSON.parse(JSON.stringify(item.cfFlowReptcontList)),
                        secondList: []
                      })
                      item.tableData[item.tableData.length - 1].list.forEach(item1 => {
                        item1.reptValue = 0
                        item1.reptValue2 = 0
                        item.tableData.forEach((val, index) => {
                          val.list.forEach(el => {
                            if(el.cfFlowReptcontId == item1.cfFlowReptcontId && index != item.tableData.length - 1) {
                              // console.log(el.reptValue);
                              item1.reptValue += Number(el.reptValue)
                            }
                          })
                          val.secondList.forEach(val1 => {
                            val1.list.forEach(el => {
                              if(el.cfFlowReptcontId == item1.cfFlowReptcontId) {
                                // console.log(el.reptValue);
                                item1.reptValue += Number(el.reptValue)
                                if(el.subdealtype == '70') {
                                  item1.reptValue2 += Number(el.reptValue2)
                                }
                              }
                            })
                          })
                        })
                      });
                    }
                  }
                })
              }else if(item.type == '4') {
                if(orgRept.flowRepttype == '1' || orgRept.flowRepttype == '2') {
                  this.$http.get(url.getOrgRept, {params: {
                    cfFlowReptId: item.cforgreptId
                  }}).then(res => {
                    if(res.data.code == 200) {
                      let series = res.data.data.map(val => {
                        let obj = val.list.find(el => el.cfFlowReptcontId == orgRept.cfFlowReptcontList[0].cfFlowReptcontId)
                        return {
                          name: val.name,
                          value: obj ? obj.reptValue : ''
                        }
                      })
                      // let series = orgRept.cfFlowReptcontList.map(val => {
                      //   return {
                      //     name: val.label,
                      //     value: 23
                      //   }
                      // })
                      this.echarts_4(series, 'echart' + index)
                    }
                  })
                }else {
                  let series = [
                    { value: 1048, name: '华为' },
                    { value: 735, name: '百度' },
                    { value: 580, name: '拼多多' },
                    { value: 484, name: '小米' },
                    { value: 300, name: 'oppo' }
                  ]
                  this.echarts_4(series, 'echart' + index)
                }
              }
              // item.cfFlowReptcontList = []
              
            })
          }
        })
      },
      // 柱状
      echarts_1(xAxis, series, echart) {
        var myChart = echarts.init(document.getElementById(echart));
        let option = {
          tooltip: {
            trigger: "axis",
          },
          legend: {
            top: "5%",
            left: "1%",
            textStyle: {
              color: "#fff",
            },
            icon: "roundRect",
          },
          xAxis: {
            type: 'category',
            data: xAxis,
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
          },
          grid:{
            // height: '60%',
            
            left: "3%",
            right: "6%",
            bottom: "3%",
            top: "80",
            containLabel: true,
          },
          series: series,
          toolbox: {
            show: true,
            orient: 'vertical',
            left: 'right',
            top: 'center',
            feature: {
              mark: { show: true },
              dataView: { show: true, readOnly: false },
              magicType: { show: true, type: ['line', 'bar', 'stack'] },
              restore: { show: true },
              saveAsImage: { show: true }
            }
          },
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
        // }
        
      },
      echarts_2(xAxis, series, echart) {
        // console.log(echart);
        // 基于准备好的dom，初始化echarts实例
        // var myEchart = document.getElementsByClassName('echart');
        var myChart = echarts.init(document.getElementById(echart));
        // console.log(myEchart);
        // for(var i = 0; i < myEchart.length; i++) {
        //   var myChart = echarts.init(myEchart[i]);
        let option = {
          tooltip: {
            trigger: "axis",
          },
          legend: {
            top: "5%",
            left: "1%",
            textStyle: {
              color: "#fff",
            },
            icon: "roundRect",
          },
          // color: ["#5470c6", "#91cc75", "#fac858", "#ee6666", "#73c0de"],
          grid: {
            left: "3%",
            right: "6%",
            bottom: "3%",
            top: "80",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: xAxis,

            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
          },
          yAxis: {
            type: "value",

            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
          },
          series: series
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
        // }
        
      },
      echarts_4(series, echart) {
        var myChart = echarts.init(document.getElementById(echart));
        let option = {
          // title: {
          //   text: 'Referer of a Website',
          //   subtext: 'Fake Data',
          //   left: 'center',
          // },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            top: "5%",
            left: "1%",
            textStyle: {
              color: "#fff",
            },
          },
          series: [
            {
              // name: 'Access From',
              type: 'pie',
              radius: '50%',
              left: "left",
              center: ['60%', `center`],
              radius: ["40%", "80%"],
              data: series,
              label: {
                show: false,
                position: 'center'
              },
            }
          ]
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
        // }
        
      },
      getData(item, tableData) {
        let arr = []
        // console.log(tableData);
        tableData.forEach(val => {
          let obj = val.list.find(el => el.cfFlowReptcontId == item.cfFlowReptcontId)
          if(obj) {
            arr.push(obj.reptValue)
          }else {
             arr.push('')
          }
        })
        return arr
      },
      getTime() {
        var t = null;
        t = setTimeout(this.getTime, 1000); //開始运行
        clearTimeout(t); //清除定时器
        let dt = new Date();
        var y = dt.getFullYear();
        var mt = dt.getMonth() + 1;
        var day = dt.getDate();
        var h = dt.getHours(); //获取时
        var m = dt.getMinutes(); //获取分
        var s = dt.getSeconds(); //获取秒
        this.time =
          y +
          "/" +
          mt +
          "/" +
          day +
          " " +
          this.add0(h) +
          ":" +
          this.add0(m) +
          ":" +
          this.add0(s) +
          "";
        // document.getElementById("showTime").innerHTML = y + "/" + mt + "/" + day + " " + add0(h) + ":" + add0(m) + ":" + add0(s) + "";
        t = setTimeout(this.getTime, 1000); //设定定时器，循环运行
      },
      add0(n) {
        if (n < 10) {
          return "0" + n;
        } else {
          return n;
        }
      },
      rowClass({row, column, rowIndex, columnIndex}){
        if(row.name == '合计') {
          return 'fixed-row'
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
$bg-color: #030e44;
  .contain {
    background: $bg-color;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    /* opacity: 0.5; */
    .head {
      position: relative;
      /* position: fixed;
      width: calc(100% - 75px); */
      height: 90px;
      background: url(@/assets/images/screen/topbg.png) center bottom no-repeat;
      background-size: 100% 100%;
      color: #daf9ff;
      h2 {
        font-size: 30px;
        text-align: center;
        line-height: 90px;
        color: #daf9ff;
      }
      .time {
        position: absolute;
        right: 40px;
        line-height: 40px;
        top: 0;
        opacity: 0.7;
      }
    }
    .main {
      padding: 20px;
      flex: 1;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      ::v-deep .el-table{
        .el-table__body {
          // 吸底
          .fixed-row{
            /* display: inline-block; */
            position: sticky;
            bottom: 0;
            width: 100%;
            background: #f5f7fa;
          }
        }
      }
      .box {
        width: 33%;
        height: 300px;
        border: 1px solid #242f67;
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        /* overflow: auto; */
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        margin-bottom: 20px;
        .tit {
          display: flex;
          align-items: flex-end;
          span {
            background: url(@/assets/images/screen/line1.png) no-repeat bottom right;
            white-space: nowrap;
            padding-bottom: 10px;
            padding-right: 20px;
            font-size: 20px;
            color: #fff;
          }
          p {
            background: url(@/assets/images/screen/line2.png) no-repeat bottom right;
            width: 100%;
            height: 13px;
            margin-bottom: 5px;
            opacity: 0.5;
          }
        }
        .flex {
          flex: 1;
          overflow: auto;
          /* padding: 10px; */
        }
        ::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td {
          /* background-color: #f19944; */
          color: #000;
        }
        ::v-deep .el-table__empty-block {
          
          color: #fff;
          background: rgba(0, 0, 0, 0.9);
          .el-table__empty-text {
            color: #fff;
          }
        }
      }
    }
  }
</style>