<template>
  <div class="entry">
    <div class="sift">
      <div class="title">报表查询</div>
      <div class="inquire">
        <el-form :inline="true" :model="formInline" class="demo-form-inline" size="small">
          <el-form-item>
            <strong slot="label">报表类型</strong>
            <el-select v-model="formInline.cfReptId" placeholder="请选择报表类型" clearable filterable @change="inquire">
              <el-option v-for="(item, index) in cffreptList" :key="index" :label="item.name" :value="item.cfReptId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <strong slot="label">日期</strong>
            <el-date-picker
              v-model="formInline.date"
              type="monthrange"
              range-separator="至"
              start-placeholder="开始月份"
              end-placeholder="结束月份"
              format="yyyy-MM"
              value-format="yyyy-MM"
              @change="inquire"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <strong slot="label">名称</strong>
            <el-input
              v-model="formInline.name"
              placeholder="请输入名称"
              clearable 
              @keyup.enter.native="inquire"
              @clear="inquire"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" plain icon="el-icon-plus" @click="handleAdd" size="small">新建</el-button>
            <el-button type="primary" icon="el-icon-search" @click="inquire" style="background:#0C75E5">查询</el-button>
            <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="main">
      <div class="title">报表列表</div>
      <!-- <el-button type="danger" plain size="small" icon="el-icon-delete" :disabled="!del" @click="handleDel" >删除</el-button> -->
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
        v-loading="loading"
      >
        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
        <el-table-column prop="countName" label="名称">
          <template slot-scope="scope">
            <a @click="handleDetail(scope.row)" class="link">{{scope.row.countName}}</a>
          </template>
        </el-table-column>
        <el-table-column prop="reportTypeName" label="类型" align="center">
          <!-- <template slot-scope="scope">
            <DictTag :options="dropDown.documentstatus" :value="scope.row.documentstatus" :isStyle="true"/>
          </template> -->
        </el-table-column>
        <el-table-column prop="code" label="时间" width="180">
          <template slot-scope="scope">
            {{scope.row.countDate | filter_handleDate}}
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="80" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click.native.stop="handleDel(scope.row)"
              type="text"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[5, 10, 20, 30, 40]"
        :page-size="pageSize"
        layout="->, total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import url from '@/config/url'
import DictTag from '@/components/DictTag.vue'
import { mapState } from 'vuex'
import handleTree from '@/utils/handleTree'
import getCurrentDate from '@/utils/getCurrentDate'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import qs from 'qs'
  export default {
    components: {
      DictTag,
      Treeselect,
    },
    computed: {
      ...mapState ({
        dropDown: state => state.dropDown.dropDown,
        tagsList: (state) => state.tags.tagsList,
      })
    },
    data() {
      return {
        tableData: [],
        loading: false,
        dialogFormVisible: false,
        formInline: {
          date: [],
          cfReptId: '',
          name: ''
        },
        ruleForm: {
          ojdtDate: '',
          documentstatus: '',
          financeType: '',
          ofrthingId: '',  
          remarks: ''
        },
        rules: {
          ojdtDate: [{ required: true, message: "请选择日期", trigger: "submit" }],
          documentstatus: [{ required: true, message: "请选择状态", trigger: "submit" }],
          financeType: [{ required: true, message: "请选择凭证类型", trigger: "submit" }],
          ofrthingId: [{ required: true, message: "请选择负责人", trigger: "submit" }],
        },
        // options: [],
        // 弹出层标题
        title: '',
        isLoading: false,
        pageNum: 1, 
        pageSize: 10,
        total: 0,
        // 编辑按钮不可点
        edit: false,
        del: false,
        list: [],
        itemList: [], // 项目列表
        cbSubjectList: [], // 科目列表
        options: [], // 树选项
        ofrthingList: [],
        cffreptList: []
      }
    },
    async created () {
      await this.$http.get(url.cffreptList).then(res => {
        if(res.data.code == 200) {
          this.cffreptList = res.data.data.list
        }
      })
      this.getList()
    },
    methods: {
      // 查询按钮
      inquire() {
        this.loading = true
        this.pageNum = 1
        this.getList()
      },
      // 重置按钮
      reset() {
        for(let key in this.formInline) {
          this.formInline[key] = ""
        }
        this.loading = true
        this.pageNum = 1
        this.getList()
      },
      getList() {
        this.$http.get(url.cffreptCountList, {params: {
          reportType: this.formInline.cfReptId,
          startTime: this.formInline.date[0] ? this.formInline.date[0] : '',
          endTime: this.formInline.date[1] ? this.formInline.date[1] : '',
          countName: this.formInline.name,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }}).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.tableData = res.data.data.list
            this.total = res.data.data.total
            this.tableData.forEach(item => {
              this.cffreptList.forEach(val => {
                if(item.reportType == val.cfReptId) {
                  item.reportTypeName = val.name
                }
              })
            })
          }
        })
      },
      handleAdd() {
        if(this.formInline.cfReptId) {
          let name = ''
          let repttype = ''
          this.cffreptList.forEach(item => {
            if(this.formInline.cfReptId == item.cfReptId) {
              name = item.name
              repttype = item.repttype
            }
          })
          this.$router.push({path: '/accounting/reports/count', query: {cfReptId: this.formInline.cfReptId, name, repttype, startDate: this.formInline.date[0] ? this.formInline.date[0] : '', endDate: this.formInline.date[1] ? this.formInline.date[1] : ''}})
          let obj = {
            path: '/accounting/reports/count',
            name: 'count',
            label: '财务',
            children: []
          }
          this.$store.commit("changeMenu", obj);
        }else {
          this.$message({
            message: '请选择报表类型',
            type: 'warning'
          });
        }
        
      },
      handleDetail(row) {
        this.$router.push('/accounting/reports/' + row.cfReptResultId)
        let obj = {
          path: '/accounting/reports/' + row.cfReptResultId,
          name: 'rDetail',
          label: '报表详情',
          offOjdtId: row.cfReptResultId,
          children: []
        }
        this.$store.commit("selectMenu", obj);
      },
      handleDel(row) {
        const id = row.cfReptResultId || this.ids.toString()
        this.$confirm('此操作将永久删除该报表, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url.deleteCffreptCount, qs.stringify({ids: id})).then(res => {
            if(res.data.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.loading = true
              this.getList()
            }
          })
          
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });          
        });
      },
      handleSelectionChange(rows) {
        this.ids = rows.map(item => item.cfReptResultId)
        // this.edit = rows.length === 1
        this.del = rows.length >= 1
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.pageNum = 1
        this.loading = true
        this.getList()
      },
      handleCurrentChange(val) {
        this.pageNum = val
        this.loading = true
        this.getList()
      },
    },
  }
</script>

<style lang="scss" scoped>
.entry {
  /* padding: 16px; */
  .sift, .main {
    background: #FFFFFF;
    box-shadow: 2px 3px 8px 0px rgba(208,208,208,0.5);
    border-radius: 11px;
    padding: 16px;
    position: relative;
    /* margin-bottom: 16px; */
    .add{
      margin-bottom: 16px;
    }
    .el-table {
      margin-top: 16px;
      margin-bottom: 39px;
    }
  }
  .sift {
    margin-bottom: 16px;
  }
  .title {
    height: 14px;
    font-size: 14px;
    font-family: STHeitiSC-Medium, STHeitiSC;
    font-weight: 500;
    color: #282828;
    line-height: 14px;
    padding-left: 8px;
    border-left: #0c75e5 2px solid;
    margin-bottom: 16px;
    font-weight: bolder;
  }
}
</style>