<template>
  <div class="main">
    <div class="contain-left" v-if="isShowLeft">
      <div v-for="(item, index) in itemList" :key="index" :class="['deal', itemIndex == index ? 'isActive' : '']" @click="changeItem(item, index)">
        <i class="el-icon-tickets" style="margin-right:5px"></i>
        {{item.name}}
      </div>
    </div>
    <div :class="isShowLeft ? 'middle iconfont icon-zhedie-l' : 'middle iconfont icon-zhedie1'" @click="isShowLeft = !isShowLeft">
    </div>
    <div class="contain-right">
      <el-tabs v-model="ofoDealId" type="border-card" @tab-click="changeDeal" class="tabs1">
        <el-tab-pane v-for="(item, index) in allData.ofoFlowDealList" :key="index" :label="item.name" :name="item.ofoDealId + ''">
          <template v-if="item.ofoFlowWorkList.length">
            <el-tabs :tab-position="'left'" class="tabs2" v-model="ofoFlowWorkId" @tab-click="changeWork">
              <el-tab-pane v-for="(val, id) in item.ofoFlowWorkList" :key="id" :label="val.name" :name="val.ofoFlowWorkId + ''">
                <div slot="label">
                  <div :class="[ofoFlowWorkId == val.ofoFlowWorkId ? 'work-isActive' : '', 'work-item']">
                    {{val.name}}
                    <el-badge :value="val.draftCount" class="item" style="position:absolute;top:-8px;right:-10px" v-if="val.draftCount"></el-badge>
                  </div>
                </div>
                <Activity
                  :ofoFlowDeal="ofoDeal"
                  v-if="(ofoFlowWorkId == val.ofoFlowWorkId + '') && ofoDealId == item.ofoDealId + ''"
                  :ofoFlowWork="ofoFlowWork"
                  :ofoItemName="allData.name"
                  :siftForm="siftForm"
                  ref="activity"
                  :isShare="isShare"
                  @changeCopy="changeCopy"/>
              </el-tab-pane>
            </el-tabs>
          </template>
          <template v-if="!item.ofoFlowWorkList.length">
            <div style="text-align:center;color:#999">暂无数据~</div>
          </template>
        </el-tab-pane>
      </el-tabs>
          <el-button size="mini" style="position: absolute;top:22px;right:22px;" @click="handleRefresh">配置更新</el-button>
      <!-- <div style="background:pink;height:900px">111</div> -->
    </div>
  </div>
</template>

<script>
import url from '@/config/url'
import Activity from '@/components/project/activity.vue'
import qs from 'qs'
  export default {
    components: {
      Activity,
    },
    data() {
      return {
        itemList: [],
        ofoItemId: '',
        ofoDealId: '',
        ofoFlowWorkId: '',
        allData: {},
        isShare: this.$route.query.isShare ? this.$route.query.isShare : '1',
        siftForm: {
          code: '',
          name: '',
          dealId: '',
          dealowhId: ''
        },
        tabIndex: 0,
        isShowLeft: true,
        itemIndex: 0,
        ofoDeal: {},
        ofoFlowWork: {}
      }
    },
    created () {
      this.getItem()
    },
    methods: {
      changeCopy(ofoDeal, ofoFlowWork) {
        this.ofoDeal = ofoDeal
        this.ofoFlowWork = ofoFlowWork
      },
      getItem() {
        this.$http.get(url.getFlowMenuList, {params: {
          itemType: '1'
        }}).then(res => {
          if(res.data.code == 200 && res.data.data) {
            this.itemList = res.data.data
            if(this.itemList) {
              this.ofoItemId = res.data.data[this.itemIndex].ofoItemId
              this.getDetail()
            }
            
          }  
        })
      },
      getDetail() {
        this.$http.get(url.getItemDetail, {params: {
          ofoItemId: this.ofoItemId,
          isShare: this.$route.query.isShare ? this.$route.query.isShare : '1'
        }}).then(res => {
          if(res.data.code == 200) {
            this.allData = res.data.data
            if(this.allData.ofoFlowDealList.length) {
              this.ofoDeal = this.allData.ofoFlowDealList[0]
              this.ofoDealId = this.ofoDeal.ofoDealId + ''
              if(this.ofoDeal.ofoFlowWorkList.length) {
                this.ofoFlowWork = this.ofoDeal.ofoFlowWorkList[0]
                this.ofoFlowWorkId = this.ofoFlowWork.ofoFlowWorkId + ''
              }
            }
            // this.ofoDealId =  res.data.data.ofoFlowDealList[0].ofoDealId + ''
            // this.ofoFlowWorkId = res.data.data.ofoFlowDealList[0].ofoFlowWorkList.length ? res.data.data.ofoFlowDealList[0].ofoFlowWorkList[0].ofoFlowWorkId + '' : ''
          }
        })
      },
      changeItem(item, index) {
        this.ofoItemId = item.ofoItemId
        this.itemIndex = index
        this.getDetail()
      },
      changeDeal(tab, event) {
        this.ofoDeal = this.allData.ofoFlowDealList[tab.index]
        this.ofoDealId = this.ofoDeal.ofoDealId + ''
        // this.ofoDealId = this.allData.ofoFlowDealList[tab.index].ofoDealId + ''
        this.tabIndex = tab.index
        if(this.ofoDeal.ofoFlowWorkList.length) {
          this.ofoFlowWork = this.ofoDeal.ofoFlowWorkList[0]
          this.ofoFlowWorkId = this.ofoFlowWork.ofoFlowWorkId + ''
        }
        // this.ofoFlowWorkId = this.allData.ofoFlowDealList[tab.index].ofoFlowWorkList.length ? this.allData.ofoFlowDealList[tab.index].ofoFlowWorkList[0].ofoFlowWorkId + '' : ''
      },
      changeWork(tab, event) {
        this.ofoFlowWork = this.ofoDeal.ofoFlowWorkList[tab.index]
        this.ofoFlowWorkId = this.ofoFlowWork.ofoFlowWorkId + ''
        // this.ofoFlowWorkId = this.allData.ofoFlowDealList[this.tabIndex].ofoFlowWorkList[tab.index].ofoFlowWorkId + ''
      },
      handleRefresh() {
        this.$confirm('此操作将更新配置, 是否更新?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url.updateItemConfig, qs.stringify({ofoItemId: this.ofoItemId})).then(res => {
          // console.log(res);
          if(res.data.code == 200){
            this.$message.success(res.data.msg)
            this.getItem()
          }
        })
        }).catch(() => {     
        });
      },
    },
  }
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  height: 100%;
  overflow: hidden;
  .contain-left {
    width: 160px;
    background-color: #fff;
    /* padding: 0 8px; */
    box-shadow: 2px 3px 8px 0px rgb(208, 208, 208);
    overflow-x: hidden;
    overflow-y: auto;
    .deal {
      padding: 0 15px 0 6px;
      font-size: 13px;
      font-family: STHeitiSC-Light, STHeitiSC;
      font-weight: 300;
      color: #282828;
      margin: 8px;
      padding: 7px;
      cursor: pointer;
    }
    .deal:hover {
      background-color: #E5F3FF;
    }
    .isActive {
      color: #177BE6;
      background-color: #E5F3FF;
    }
    
  }
  .middle {
    height: 100%;
    width: 20px;
    display: flex;
    align-items: center;
    font-size: 30px;
    color: #79bbff;
  }
  .middle:hover {
    color: #0c75e5;
  }
  .contain-right {
    padding: 16px;
    flex: 1;
    overflow: auto;
    padding-left: 0;
    position: relative;
    .tabs1 {
      ::v-deep .el-tabs__nav {
        z-index: 1;
      }
      .tabs2 {
        ::v-deep .el-tabs__header {
          /* border: 0; */
          margin-right: 30px;
        }
        ::v-deep .el-tabs__item {
          background: #FFFFFF !important;
          border: 1px solid #0C75E5 !important;
          /* margin-bottom: 16px; */
          /* padding: 6px 10px !important; */
          text-align: center !important;
          margin-right: 0 !important;
          height: 30px !important;
          line-height: 30px !important;
          position: relative !important;
          margin: 8px 10px 16px 0 !important;
        }
        /* ::v-deep .el-tabs__item::before {
          border: 0 !important;
        }*/
        ::v-deep .el-tabs__active-bar.is-left {
          width: 0;
        }
        ::v-deep .el-tabs__nav-wrap.is-left::after {
          width: 0 !important;
        } 
        ::v-deep .el-tabs__item.is-active {
          color: #fff !important;
          background-color: #0C75E5 !important;

        }
      }
    }
  }

::-webkit-scrollbar {
  width: 5px;
  height: 5px; 
  background-color: #fff;
}

// 定义滚动条轨道 内阴影
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #fff;
}

// 定义滑块 内阴影
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #ddd;
  border-radius: 10px;
}

  




 
}
</style>