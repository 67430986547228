const prefix = process.env.VUE_APP_URLPREFIX

const url = {
  // 用户登录
  login: '/api/user/login',
  // 验证码
  code: 'https://www.mxnzp.com/api/verifycode/code?len=4&type=0&app_id=cjjgmmeykludftwi&app_secret=MzdiN09LM3QwRjVDaWc1UlBFQkViUT09',


  // 获取字典数据
  getDataList: '/api/utils/getDataList',

  // 获取项目列表
  getItemList: '/api/oflow/getItemList',
  // 新增项目
  addItem: '/api/oflow/addItem',
  // 获取项目详情
  getItemDetail: '/api/oflow/getItemDetail',
  // 获取项目内容
  getItem: '/api/oflow/getItem',
  // 修改项目
  editItem: '/api/oflow/editItem',
  // 获取项目列表
  getAllItemList: '/api/oflow/getAllItemList',
  // 删除项目
  removeItem: '/api/oflow/removeItem',
  // 获取交易流程和活动流程
  getFlowDealAndWork: '/api/oflow/getFlowDealAndWork',
  // 获取项目附件
  getItemFileList: '/api/oflow/getItemFileList',
  // 获取流程菜单
  getFlowMenuList: '/api/oflow/getFlowMenuList',
  // 获取项目报表
  getItemRept: '/api/oflow/getItemRept',
  // 根据显示修改项目
  editItemByShow: '/api/oflow/editItemByShow',
  // 获取项目活动资源行配置
  getItemWorkConfig: '/api/oflow/getItemWorkConfig',
  // 导出项目附件
  exportItemFile: '/api/oflow/exportItemFile',
  exportItemFile1: '/api/oflow/exportItemFile1',


  // 获取本地资源类型列表
  getResTypeList: '/api/cbResType/getResTypeList',
  // 新增资源类型
  addCbResType: '/api/cbResType/addCbResType',
  // 修改本地资源类型
  editCbResType: '/api/cbResType/editCbResType',
  // 删除本地资源类型
  removeCbResType: '/api/cbResType/removeCbResType',
  // 获取本地资源类型(单)
  getResType: '/api/cbResType/getResType',
  // 获取本地资源类型树
  getResTypeTree: '/api/cbResType/getResTypeTree',
  // 获取本地资源类型以及资源内容(单)
  getResTypeAndList: '/api/cbResType/getResTypeAndList',
  // 获取本地资源类型以及资源内容(多)
  getResTypesAndList: '/api/cbResType/getResTypesAndList',
  // 获取资源类型(多)
  getResTypes: '/api/cbResType/getResTypes',

  // 获取位置树
  getCbOwhTree: '/api/cbOwh/getCbOwhTree',
  // 新增本地位置
  addCbOwh: '/api/cbOwh/addCbOwh',
  // 修改本地位置
  editCbOwh: '/api/cbOwh/editCbOwh',
  // 删除本地位置
  removeCbOwh: '/api/cbOwh/removeCbOwh',
  // 获取本地位置
  getCbOwh: '/api/cbOwh/getCbOwh',
  // 根据资源类型查询位置
  getCbOwhByCbResType: '/api/cbOwh/getCbOwhByCbResType',

  // 获取维度列表
  getDimeList: '/api/dime/getDimeList',
  // 获取本地资源或内容维度列表
  getCbResTypeDimeList: '/api/cbResTypeDime/getCbResTypeDimeList',
  // 新增本地资源或内容维度
  addCbResTypeDime: '/api/cbResTypeDime/addCbResTypeDime',
  // 修改本地资源或内容维度
  editCbResTypeDime: '/api/cbResTypeDime/editCbResTypeDime',
  // 删除本地资源或内容维度
  removeCbResTypeDime: '/api/cbResTypeDime/removeCbResTypeDime',
  // 获取本地资源或内容维度
  getCbResTypeDime: '/api/cbResTypeDime/getCbResTypeDime',

  // 获取资源列表
  getOfrResourceList: '/api/ofrResource/getOfrResourceList',
  // 新增资源
  addOfrResource: '/api/ofrResource/addOfrResource',
  // 修改资源
  editOfrResource: '/api/ofrResource/editOfrResource',
  // 删除资源
  removeOfrResource: '/api/ofrResource/removeOfrResource',
  // 获取资源
  getOfrResource: '/api/ofrResource/getOfrResource',
  // 资源判断重复
  getOfrResourceByName: '/api/ofrResource/getOfrResourceByName',
  // 获取所有资源列表
  getAllOfrResourceList: '/api/ofrResource/getAllOfrResourceList',
  // 修改本地资源归属人
  editOfrResourceBelong: '/api/ofrResource/editOfrResourceBelong',
  // 获取资源列表(位置)
  getCbowhOfrResourceList: '/api/ofrResource/getCbowhOfrResourceList',
  // 获取物料下的资源
  resTypeResource: '/api/ofrResource/resTypeResource',
  // 添加BOM资源
  addBomResource: '/api/ofrResource/addBomResource',
  bomResource: '/api/ofrResource/bomResource/',
  removeBomResource: '/api/ofrResource/removeBomResource/',
  editBomResource: '/api/ofrResource/editBomResource',
  bomDetail: '/api/ofrResource/bomDetail/',
  copyBom: '/api/ofrResource/copyBom',

  // 上传文件
  uploadFile: '/api/utils/uploadFile',

  // 获取内容列表
  getOfrThingList: '/api/ofrThing/getOfrThingList',
  // 新增内容
  addOfrThing: '/api/ofrThing/addOfrThing',
  // 修改内容
  editOfrThing: '/api/ofrThing/editOfrThing',
  // 删除内容
  removeOfrThing: '/api/ofrThing/removeOfrThing',
  // 获取内容
  getOfrThing: '/api/ofrThing/getOfrThing',
  // 内容判断重复
  getOfrThingByName: '/api/ofrThing/getOfrThingByName',
  // 获取所有内容列表
  getAllOfrThingList: '/api/ofrThing/getAllOfrThingList',


  // 新增活动
  addWork: '/api/ofoWork/addWork',
  // 获取活动列表
  getWorkList: '/api/ofoWork/getWorkList',
  // 获取活动
  getWork: '/api/ofoWork/getWork',
  // 编辑活动
  editWork: '/api/ofoWork/editWork',
  // 修改活动状态
  editWorkStatus: '/api/ofoWork/editWorkStatus',
  // 新增活动内容
  addWorkOfrThing: '/api/ofoWork/addWorkOfrThing',
  // 获取活动内容列表
  getWorkOfrThingList: '/api/ofoWork/getWorkOfrThingList',
  // 获取活动复制从列表
  getWorkFromList: '/api/ofoWork/getWorkFromList',
  // 获取活动导航图
  getWorkProcess: '/api/ofoWork/getWorkProcess',
  // 新增活动明细
  addWorkThing: '/api/ofoWork/addWorkThing',
  // 获取活动产品及服务列表树
  getWorkResourceList: '/api/ofoWork/getWorkResourceList',
  // 获取活动跳转所需id
  getWorkJumpId: '/api/ofoWork/getWorkJumpId',
  // 删除活动
  removeWork: '/api/ofoWork/removeWork',
  // 获取活动附件列表
  getOfoFlowWorkfileList: '/api/ofoWork/getOfoFlowWorkfileList',
  // 获取活动编码提示列表
  getWorkCodeList: '/api/ofoWork/getWorkCodeList',
  // 获取活动复制到列表（协同用到）
  getWorkCopyToList: '/api/ofoWork/getWorkCopyToList',
  // 获取交易方列表（活动搜索用到）
  getDealList: '/api/ofoWork/getDealList',
  // 获取活动产品和明细
  getWorkResAndThingList: '/api/ofoWork/getWorkResAndThingList',
  // 获取活动列表（通过二楼的流程ID）
  getWorkListBycf: '/api/ofoWork/workList/cfFlow',
  // 更改活动项目
  changeWorkItem: '/api/ofoWork/changeWorkItem',
  // 活动导入模板导出
  importTemplate: '/api/ofoWork/importTemplate/',
  // 活动导入模板导入
  importData: '/api/ofoWork/importData',

  // 审批
  // 发起审批
  startOsApp: '/api/osApp/startOsApp',
  // 提交审批
  submitOsApp: '/api/osApp/submitOsApp',
  // 获取审批列表
  getMyOsApp: '/api/osApp/getMyOsApp',
  // 获取审批步骤列表
  getMyOsAppStep: '/api/osApp/getMyOsAppStep',
  // 撤回审批
  backOsApp: '/api/osApp/backOsApp',

  // 项目更新配置
  updateItemConfig: '/api/oflow/updateItemConfig',

  // 获取维度配置列表
  getCfBussDimeList: '/api/cfBussDime/getCfBussDimeList',
  // 新增维度配置
  addCfBussDime: '/api/cfBussDime/addCfBussDime',
  // 修改维度配置
  editCfBussDime: '/api/cfBussDime/editCfBussDime',
  // 删除维度配置
  removeCfBussDime: '/api/cfBussDime/removeCfBussDime',
  // 获取维度配置
  getCfBussDime: '/api/cfBussDime/getCfBussDime',

  // 企业相关
  // 查询公司列表
  getOrgList: '/api/org/getOrgList',
  // 查询部门及人员
  getDeptAndUser: '/api/org/getDeptAndUser',
  // 查询岗位列表
  getPostList: '/api/org/getPostList',
  // 查询岗位及人员列表
  getPostAndUserList: '/api/org/getPostAndUserList',

  // 本地维度
  // 获取本地维度列表
  getCbDimeList: '/api/cbDime/getCbDimeList',
  // 新增本地维度
  addCbDime: '/api/cbDime/addCbDime',
  // 修改本地维度
  editCbDime: '/api/cbDime/editCbDime',
  // 删除本地维度
  removeCbDime: '/api/cbDime/removeCbDime',
  // 获取本地维度
  getCbDime: '/api/cbDime/getCbDime',
  // 获取本地维度树
  getCbDimeTree: '/api/cbDime/getCbDimeTree',

  // 本地业务维度配置
  // 获取业务维度配置列表
  getCbussDimeList: '/api/cbussDime/getCbussDimeList',
  // 新增业务维度配置
  addCbussDime: '/api/cbussDime/addCbussDime',
  // 修改业务维度配置
  editCbussDime: '/api/cbussDime/editCbussDime',
  // 获取业务维度配置
  getCbussDime: '/api/cbussDime/getCbussDime',
  // 删除业务维度配置
  removeCbussDime: '/api/cbussDime/removeCbussDime',

  // // 权限配置
  // // 新增权限
  // addCbDuty: '/api/cbDuty/addCbDuty',
  // // 根据流程id获取已分配的岗位id
  // getAllocatedPost: '/api/cbDuty/getAllocatedPost',

  // 导入导出
  // 本地资源
  // 获取本地资源类型列表
  getImportResTypeList: '/api/import/getResTypeList',
  // 导出本地资源类型模板
  exportResTypeList: '/api/import/exportResTypeList',
  // 导入本地资源类型
  importResTypeList: '/api/import/importResTypeList',
  // 项目流程
  // 获取项目流程列表
  getImportCflowItemList: '/api/import/getCflowItemList',
  // 导出项目流程模板
  exportCflowItemList: '/api/import/exportCflowItemList',
  // 导入项目流程
  importCflowItemList: '/api/import/importCflowItemList',

  // 审批流程
  // 获取审批流程列表
  getCfAppList: '/api/cfApp/getCfAppList',
  // 新增审批流程
  addCfApp: '/api/cfApp/addCfApp',
  // 修改审批流程
  editCfApp: '/api/cfApp/editCfApp',
  // 删除审批流程
  removeCfApp: '/api/cfApp/removeCfApp',
  // 获取审批流程
  getCfApp: '/api/cfApp/getCfApp',

  // 审批流程配置
  // 审批步骤
  // 列表
  getStepList: '/api/cfAppConfig/getStepList',
  // 新增
  addStep: '/api/cfAppConfig/addStep',
  // 删除
  removeStep: '/api/cfAppConfig/removeStep',
  // 排序更新
  updateStepSort: '/api/cfAppConfig/updateStepSort',
  // 修改
  editStep: '/api/cfAppConfig/editStep',
  // 发起岗位
  // 列表
  getCfAppResourceList: '/api/cfAppConfig/getCfAppResourceList',
  // 新增
  addCfAppResource: '/api/cfAppConfig/addCfAppResource',
  // 删除
  removeCfAppResource: '/api/cfAppConfig/removeCfAppResource',
  // 审批条件
  // 列表
  getCfAppCondList: '/api/cfAppConfig/getCfAppCondList',
  // 新增
  addCfAppCond: '/api/cfAppConfig/addCfAppCond',
  // 删除
  removeCfAppCond: '/api/cfAppConfig/removeCfAppCond',
  // 发起活动
  // 列表
  getCfAppWorkList: '/api/cfAppConfig/getCfAppWorkList',
  // 新增
  addCfAppWork: '/api/cfAppConfig/addCfAppWork',
  // 删除
  removeCfAppWork: '/api/cfAppConfig/removeCfAppWork',

  // 审批组件步骤
  // 获取审批组件步骤列表
  getCfAppUsList: '/api/cfAppUs/getCfAppUsList',
  // 获取所有审批组件步骤列表
  getAllCfAppUsList: '/api/cfAppUs/getAllCfAppUsList',
  // 新增审批组件步骤
  addCfAppUs: '/api/cfAppUs/addCfAppUs',
  // 修改审批组件步骤
  editCfAppUs: '/api/cfAppUs/editCfAppUs',
  // 删除审批组件步骤
  removeCfAppUs: '/api/cfAppUs/removeCfAppUs',
  // 获取审批组件步骤
  getCfAppUs: '/api/cfAppUs/getCfAppUs',
  // （岗位）获取审批组件步骤岗位列表
  getCfAppUsrList: '/api/cfAppUs/getCfAppUsrList',
  // （岗位）新增审批组件步骤岗位
  addCfAppUsr: '/api/cfAppUs/addCfAppUsr',
  // （岗位）删除审批组件步骤岗位
  removeCfAppUsr: '/api/cfAppUs/removeCfAppUsr',

  // 审批组件条件
  // 获取审批组件条件列表
  getCfAppUcList: '/api/cfAppUc/getCfAppUcList',
  // 获取所有审批组件条件列表
  getAllCfAppUcList: '/api/cfAppUc/getAllCfAppUcList',
  // 新增审批组件条件
  addCfAppUc: '/api/cfAppUc/addCfAppUc',
  // 修改审批组件条件
  editCfAppUc: '/api/cfAppUc/editCfAppUc',
  // 删除审批组件条件
  removeCfAppUc: '/api/cfAppUc/removeCfAppUc',
  // 获取审批组件条件
  getCfAppUc: '/api/cfAppUc/getCfAppUc',

  // 获取项目流程模板（类型下拉）
  getCflowItemList: '/api/cflow/getCflowItemList',
  // 获取项目流程树
  getCflowItemTree: '/api/cflow/getCflowItemTree',
  // 获取项目流程详情
  getCflowItemDetail: '/api/cflow/getCflowItemDetail',
  // 获取项目流程报表配置
  getCflowReptList: '/api/cflow/getCflowReptList',


  // 用户
  // 获取登录用户信息
  getLoginUser: '/api/user/getLoginUser',
  // 获取所有用户列表
  getAllUserList: '/api/user/getAllUserList',
  // 获取用户列表
  getUserList: '/api/user/getUserList',
  // 新建用户
  addUser: '/api/user/addUser',
  // 修改用户
  editUser: '/api/user/editUser',
  // 删除用户
  removeUser: '/api/user/removeUser',
  // 获取用户
  getUser: '/api/user/getUser',
  // 重置密码
  resetPwd: '/api/user/resetPwd',

  // 消息
  // 获取消息列表
  getMsgList: '/api/msg/getMsgList',
  // 标记已读
  changeRead: '/api/msg/changeRead',
  // 删除消息
  removeMsg: '/api/msg/removeMsg',
  // 获取未读消息数量
  getMsgCount: '/api/msg/getMsgCount',
  // 获取消息
  getMsg: '/api/msg/getMsg',
  // 协同消息跳转所需数据
  getMsgJump: '/api/msg/getMsgJump',

  // 财务凭证
  // 获取凭证列表
  getOffOjdtList: '/api/offOjdt/getOffOjdtList',
  // 新增凭证
  addOffOjdt: '/api/offOjdt/addOffOjdt',
  // 修改凭证
  editOffOjdt: '/api/offOjdt/editOffOjdt',
  // 删除凭证
  removeOffOjdt: '/api/offOjdt/removeOffOjdt',
  // 获取凭证
  getOffOjdt: '/api/offOjdt/getOffOjdt',
  // 批量更新凭证单据状态
  batchUpdateDocumentStatus: '/api/offOjdt/batchUpdateDocumentStatus',
  // 获取冲销凭证
  getReverseOffOjdt: '/api/offOjdt/getReverseOffOjdt',

  // 本地科目
  // 获取本地科目列表
  getCbSubjectList: '/api/cbSubject/getCbSubjectList',
  // 新增本地科目
  addCbSubject: '/api/cbSubject/addCbSubject',
  // 修改本地科目
  editCbSubject: '/api/cbSubject/editCbSubject',
  // 获取本地科目详细信息
  getCbSubject: '/api/cbSubject/getCbSubject',
  // 删除本地科目
  removeCbSubject: '/api/cbSubject/removeCbSubject',
  // 判断科目编号是否重复
  validateCbSubjectNumber: '/api/cbSubject/validateCbSubjectNumber',
  // 获取现金流列表
  getCashList: '/api/cbSubject/getCashList',

  // 微信登录
  // 扫码登录PC接口
  loginPc: '/api/wx/loginPc',
  // 微信绑定用户
  bindUser: '/api/wx/bindUser',

  // 客户端菜单
  // 获取菜单列表
  getMenuList: '/api/menu/getMenuList',
  // 获取首页菜单
  getIndexMenuList: '/api/menu/getIndexMenuList',
  // 获取首页数量
  getIndexCount: '/api/menu/getIndexCount',

  // 收藏分享
  shareCollect: '/api/share/shareCollect',
  // 取消收藏分享
  cancelShareCollect: '/api/share/cancelShareCollect',
  // 获取分享给我列表
  getShareList: '/api/share/getShareList',
  // 获取收藏列表
  getCollectList: '/api/share/getCollectList',
  // 获取分享人员列表
  getShareToList: '/api/share/getShareToList',
  // 分享已读
  editShareStatus: '/api/share/editShareStatus',

  // 获取业务显示配置列表
  getCbussShowList: '/api/cbussShow/getCbussShowList',

  // 库存
  // 获取库存列表
  getOwhResourceList: '/api/owhResource/getOwhResourceList',
  // 获取部门人员列表
  getOwhUserList: '/api/owhResource/getOwhUserList',
  // 获取库存列表（根据产品id和位置id）
  getOwhResourceListById: '/api/owhResource/getOwhResourceListById',
  // 获取部门列表
  getDeptList: '/api/org/getDeptList',
  // 删除库存
  removeOwhResource: '/api/owhResource/removeOwhResource',
  // 获取库存报表
  owhResourceReport: '/api/owhResource/owhResourceReport',
  // 库存报表上的资源查询树
  resourceTree: '/api/owhResource/resource/tree',

  // 活动核销
  // 获取可核销活动列表
  getChooseCheckWorkList: '/api/ofoWorkCheck/getChooseCheckWorkList',
  // 新增活动核销
  addWorkCheck: '/api/ofoWorkCheck/addWorkCheck',
  // 获取核销活动列表
  getWorkCheckList: '/api/ofoWorkCheck/getWorkCheckList',

  // 财务账簿
  // 左侧科目树
  cbSubjectTreeList: '/api/accounting/cbSubjectTreeList',
  // 查看明细账
  detailAccount: '/api/accounting/detailAccount',
  // 查看总账
  totalAccount: '/api/accounting/totalAccount',
  // 科目余额表
  accountBalanceTable: '/api/accounting/accountBalanceTable',
  // 查看总分类账
  totalAccountSubject: '/api/accounting/totalAccountSubject',
  // 查询末级明细分类账
  totalAccountLeafSubject: '/api/accounting/totalAccountLeafSubject',

  // 财务结账
  // 生成结账凭证
  genCloseAccountsOffOjdt: '/api/closeAccounts/genCloseAccountsOffOjdt',
  // 新增凭证
  addOffOjdt1: '/api/closeAccounts/addOffOjdt',

  // 报表结果
  // 查询报表列表
  cffreptCountList: '/api/cffreptcount/list',
  // 删除报表
  deleteCffreptCount: '/api/cffreptcount/delete',
  // 报表统计
  cffreptCount: '/api/cffreptcount/count',
  // 保存统计
  insertCffreptCount: '/api/cffreptcount/insert',
  // 获取报表详细信息
  getCffreptCountDetail: '/api/cffreptcount/getinfo',


  // 报表管理
  // 查询报表列表
  cffreptList: '/api/cffrept/list',
  // 新增报表
  addRept: '/api/cffrept/addRept',
  // 修改报表
  editRept: '/api/cffrept/editRept',
  // 删除报表
  deleteRept: '/api/cffrept/deleteRept',
  // 获取报表详情信息
  getRept: '/api/cffrept/getRept',

  // 获取期间列表
  getCbTimeList: '/api/cbTime/getCbTimeList',

  // 报表内容管理
  // 报表内容管理列表
  cffreptContList: '/api/cffreptCont/list',
  // 新增报表内容
  addReptCont: '/api/cffreptCont/addReptCont',
  // 修改报表内容
  editReptCont: '/api/cffreptCont/editReptCont',
  // 删除报表内容
  deleteCfReptCont: '/api/cffreptCont/deleteCfReptCont',
  // 获取报表内容详细信息
  getReptCont: '/api/cffreptCont/getReptCont',

  // 报表内容科目
  // 查询报表内容科目列表
  cffreptContacctList: '/api/cffreptContacct/list',
  // 新增报表内容科目
  addReptContacct: '/api/cffreptContacct/addReptContacct',
  // 修改报表内容科目
  editReptContacct: '/api/cffreptContacct/editReptContacct',
  // 删除报表内容科目
  deleteReptContacct: '/api/cffreptContacct/deleteReptContacct',
  // 获取报表内容科目详细信息
  getReptContacct: '/api/cffreptContacct/getReptContacct',

  // 企业配置
  // 查看配置
  getConfigs: '/api/cbConfig/getConfigs',
  // 更新配置
  updateConfigs: '/api/cbConfig/updateConfigs',

  // 财务账簿科目细分
  // 查看现金总账
  cashTotalAccount: '/api/accountingSubject/cashTotalAccount',
  // 查看现金明细账
  cashDetailAccount: '/api/accountingSubject/cashDetailAccount',
  // 查看往来应付账簿
  currentPayable: '/api/accountingSubject/currentPayable',
  // 查看往来应收账簿
  currentReceivable: '/api/accountingSubject/currentReceivable',
  // 查看存货账簿
  inventoryAccount: '/api/accountingSubject/inventoryAccount',
  // 查看成本账簿
  costAccount: '/api/accountingSubject/costAccount',
  // 资产明细账
  assetsDetail: '/api/accountingSubject/assetsDetail',
  // 折旧汇总表
  depreciationTotal: '/api/accountingSubject/depreciationTotal',
  // 折旧明细表
  depreciationDetail: '/api/accountingSubject/depreciationDetail',

  // 大屏
  // // 获取租金收入情况
  // rentIncome: '/api/vis/rentIncome',
  // 获取大楼信息数据
  buildingInfo: '/api/vis/buildingInfo',
  // 企业考核情况
  comRank: '/api/vis/comRank',
  // 企业培育情况
  comTrain: '/api/vis/comTrain',
  // 产业分类
  comTypeRatio: '/api/vis/comTypeRatio',
  // 获取到期租赁提醒数据
  expireRent: '/api/vis/expireRent',
  // 资产出租情况
  generalInfo: '/api/vis/generalInfo',
  // 租金收益情况
  incomeMonthly: '/api/vis/incomeMonthly',
  // 获取新增租赁提醒数据
  newRent: '/api/vis/newRent',
  // 物业费用收益情况
  incomeMonthlyProperty: '/api/vis/incomeMonthlyProperty',

  // 获取公司报表列表
  getCfOrgReptList: '/api/cforg/getCfOrgReptList',
  // 获取公司报表
  getOrgRept: '/api/cforg/getOrgRept',
  // 企业报表弹窗
  getAttendUserLogs: '/api/attendance/getAttendUserLogs',

  // 组管理
  // 获取组列表
  getGroupList: '/api/group/getGroupList',
  // 新增组
  addGroup: '/api/group/addGroup',
  // 修改组
  editGroup: '/api/group/editGroup',
  // 删除组
  removeGroup: '/api/group/removeGroup',

  // 数据看板
  getCfDboard: '/api/cfDboard/getCfDboard',

  // 内容别名
  // 新增
  addThingalias: '/api/thingalias/add',
  // 编辑
  editThingalias: '/api/thingalias/edit',
  // 查询别名信息
  infoThingalias: '/api/thingalias/info/',
  // 获取详细信息,
  getThingalias: '/api/thingalias/info/',
  // 查询内容别名列表
  getThingaliasList: '/api/thingalias/list',
  // 删除
  removeThingalias: '/api/thingalias/remove/',

  progress: '/api/utils/progress/',
  // 复制bom 查询有bom的资源 （物料 + 产品）
  copyBomResources: '/api/ofrResource/copyBomResources'
}
export default url
