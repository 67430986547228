export default function handleList(list, ofoFlowDeal, oldOfoDealId, ofoFlowWork, cbOwhData, name) {
  let length1 = cbOwhData.length
  // console.log(list);
  let arr = []
  list.forEach((item,index) => {
    // item.baseOfoWorkResourceId = item.ofoWorkResourceId
    item['baseO' + name] = item['o' + name]
    item['o' + name] = ''
    item.baseOfoWorkId = item.ofoworkId
    if(item.cbowhId && length1) {
      let id = cbOwhData.findIndex(val => val.cbOwhId == item.cbowhId)
      if(id < 0) {
        item.cbowhId = ''
        item.cbowhName = ''
      }
    }else {
      item.cbowhId = ''
      item.cbowhName = ''
    }
    if(ofoFlowWork.costmanagetype == '4') { // 费用模式
      item.quantity = 1
      item.total = item.total.toFixed(2)
      item.price = item.total
      item.totalVat = 0
      item.vatgroup = '0.00'
      item.cost = 0
      item.costTotal = 0
      item.costTotalVat = 0
    }else {
      if(ofoFlowDeal.ofoDealId == oldOfoDealId) { // 交易内
        item.quantity = (!ofoFlowWork.beforeworkConfig || ofoFlowWork.beforeworkConfig.indexOf('1') != -1) ? item.openqty : 0
        // 金额
        item.total = (item.opentotal && (!ofoFlowWork.beforeworkConfig || ofoFlowWork.beforeworkConfig.indexOf('3') != -1)) ? item.opentotal.toFixed(2) : ''
      }else { // 交易外
        item.quantity = item.openqtyDeal
        // 金额
        item.total = (item.opentotal && (!ofoFlowWork.beforeworkConfig || ofoFlowWork.beforeworkConfig.indexOf('3') != -1)) ? item.opentotal.toFixed(2) : ''
      }
      if(item.quantity) {
        if(item.total) {
          // 单价
          item.price = (item.total / item.quantity).toFixed(2)
        }else {
          item.price = ''
        }
        if(item.costTotal) {
          // 成本单价
          item.cost = (item.costTotal / item.quantity).toFixed(2)
        }else {
          item.cost = ''
        }
      }
      // 税额
      item.totalVat = (item.total / (1 + Number(item.vatgroup)) * item.vatgroup).toFixed(2)
      // 成本税额
      item.costTotalVat = (item.costTotal / (1 + Number(item.vatgroup)) * item.vatgroup).toFixed(2)
    }
    if(item.quantity) {
      arr.push(item)
    }
  })
  // console.log(arr);
  return arr
}